import React, { useEffect } from 'react';
import '../Styles/siginstyles.scss';


const UnSubscribe = () => {


    useEffect(() => {
        let params = new URL(document.location).searchParams;
        let token = params.get('token');
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Authorization': "JWT " + token,
        //     'Content-Type': 'application/json',
        //     'accept': 'application/json' },
        //     body: JSON.stringify({ type: "False" })
        // }
        // fetch('https://api.joinworkahub.com/api/subscribe_report/', requestOptions)
        //     .then(response => console.log(response.json()))
        fetch('https://api.joinworkahub.com/api/subscribe_report/?token='+token)
        .then(response => response.json())
        .then(data => console.log(data));
    }, [])

    

    return (
        <div style={{ padding: '10px' }}>
            <div className="unsubscribe-nav"></div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <img
                    src="https://s7works.s3.amazonaws.com/workahub/Images/WORKAHUBLogo.png"
                    alt="workahub"
                    style={{ width: '125px', margin: '25px 0px' }}
                />
                <div className="unsubscribe-body">
                    <img
                        src="https://s7works.s3.amazonaws.com/workahub/Images/MailRestrictedIcon.png"
                        alt="mail-img"
                        style={{ width: '100px' }}
                    />
                    <p className="unsubscribe-para1">SAD TO SEE YOU GO</p>
                    <p className="unsubscribe-para2">IT WON’T BE THE SAME WITHOUT YOU!</p>
                    <p className="unsubscribe-para3">You have been removed from our mail list.</p>
                    <p className="unsubscribe-para4">FOLLOW US ON SOCIAL MEDIA</p>
                    <div className="unsubscribe-button-cont">
                        <a href="http://twitter.com/home" className="unsubscribe-button">
                            TWITTER
                        </a>
                        <a
                            href="http://www.linkedin.com/company/workahub/"
                            className="unsubscribe-button"
                        >
                            LINKEDIN
                        </a>
                        <a href="http://www.instagram.com/workahub/" className="unsubscribe-button">
                            INSTAGRAM
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnSubscribe;
