/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { Link } from 'react-router-dom';
import '../Styles/Dashboard.scss';

import { useTranslation } from 'react-i18next';
import LanguageSelection from './LanguageSelection';

const validEmailRegex = RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
);

const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
};

const Subscribe = (props) => {
    
    const [email, setEmail] = useState('');
    const [app, setApp] = useState('');
    const [errors, setErrors] = useState('');

    const {t} = useTranslation('common');

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        // let errors = this.state.errors;

        if (!validEmailRegex.test(value)) {
            setErrors('Invalid Email!');
        }
        setEmail(value);

        // if (name == 'email') {
        //     errors.email = validEmailRegex.test(value) ? '' : 'Email is not valid!';
        // }
        // setErrors()
        // this.setState({ errors, [name]: event.target.value, msg: '' });
    };

    const SubdcribeEmail = async (e) => {
        e.preventDefault();

        const data = { email: email, app: 'workahub' };
        if(email === '') {
            alert('Please enter your email');
        } else if (!validEmailRegex.test(data.email)) {
            alert('Please enter a valid email');
        } else {
            await fetch('https://api.trypadlock.com/users/subscribe_email', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                // const resData = await response.json()
                .then((response) => {
                    if (response.status === 200) {
                        alert('you have been subscribed to our email list');
                        //   handleClose()
                        // console.log('response', response.data);
                        // props.InviteEmployee()
                        // this.resetForm()
                    } else if (response.status === 400) {
                        alert('Message failed to send.');
                    }
                });
        }
    };

    const onScroll = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    const love = (
        <img style={{ height: 20, width: 20, marginBottom: 5 }} src={'/assets/love.png'} />
    );

    const url = window.location.href;
    let wow = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];

    return (
        <div
            className="container px-0 mx-auto footer-section-content"
            style={{ marginTop: '60px' }}
        >
            <div className="container-fluid sub_color">
                <div className="mx-auto mt-0 mb-3 row justify-content-between">
                    <div className="col-md-3 m-t-25 time_s">
                        <div style={{ textAlign: 'left' }}>
                            {wow == 'joinworkahub.com' ? (
                                <img
                                    className="footer-logo"
                                    style={{ width: '70px', height: '70px' }}
                                    src={'/assets/WLogo.svg'}
                                />
                            ) : wow == 'getsmashwork.com' || wow == 'localhost:3000' ? (
                                <img
                                    className="footer-logo"
                                    style={{ width: '180px', height: '70px' }}
                                    src={'/assets/SmashworkLogo.svg'}
                                />
                            ) : (
                                <img
                                    className="footer-logo"
                                    style={{ width: '70px', height: '70px' }}
                                    src={'/assets/samTapLogo.png'}
                                />
                            )}

                            <p className="workahub_text1">
                                {t('dashboard.subscribeModule.firstText')}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 m-t-25" style={{ paddingLeft: 0 }}>
                        <div className="footer-social text-left">
                            <h4>{t('dashboard.subscribeModule.middleText')}</h4>

                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    id="mail"
                                    name="email"
                                    className="form-control"
                                    placeholder={t('dashboard.subscribeModule.joinouremail')}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                    noValidate
                                />
                                <div className="input-group-prepend">
                                    <button
                                        className="btn btn_subscribe"
                                        type="button"
                                        onClick={(e) => {
                                            SubdcribeEmail(e);
                                        }}
                                    >
                                        {t('dashboard.subscribeModule.subscribe')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" p-0 col-md-2 m-t-25 quick_s">
                        <div className="footer-nav-link" style={{ textAlign: 'left' }}>
                            <h4 className="color_blk pl-2">
                                {t('dashboard.subscribeModule.quickLinks')}
                            </h4>
                            <div className="d-flex flex-wrap d-md-block">
                                <div
                                    onClick={() => {
                                        onScroll();
                                    }}
                                >
                                    <Link
                                        to={`${
                                            localStorage.getItem('language') == null ||
                                            localStorage.getItem('language') == undefined
                                                ? '/en'
                                                : `/${localStorage.getItem('language')}`
                                        }/home`}
                                        className="btn btn-workahub_home"
                                        style={{ border: '1px solid transparent' }}
                                    >
                                        {t('dashboard.home')}
                                    </Link>
                                </div>
                                {/* <div>
                                <Link to="/pricing" className="btn btn-workahub_pricing">
                                    {t('dashboard.pricing')}
                                </Link>
                            </div> */}
                                <div>
                                    <Link
                                        to={`${
                                            localStorage.getItem('language') == null ||
                                            localStorage.getItem('language') == undefined
                                                ? '/en'
                                                : `/${localStorage.getItem('language')}`
                                        }/features`}
                                        className="btn btn-workahub_features"
                                    >
                                        {t('dashboard.features')}
                                    </Link>
                                </div>
                                <div>
                                    <Link
                                        to={`${
                                            localStorage.getItem('language') == null ||
                                            localStorage.getItem('language') == undefined
                                                ? '/en'
                                                : `/${localStorage.getItem('language')}`
                                        }/download`}
                                        className="btn btn-workahub_about"
                                    >
                                        {t('dashboard.download')}
                                    </Link>
                                </div>
                                <div>
                                    <Link
                                        to={`${
                                            localStorage.getItem('language') == null ||
                                            localStorage.getItem('language') == undefined
                                                ? '/en'
                                                : `/${localStorage.getItem('language')}`
                                        }/support`}
                                        className="btn btn-workahub_about"
                                    >
                                        {t('dashboard.subscribeModule.support')}
                                    </Link>
                                </div>

                                {wow != 'joinworkahub.com' && wow != 'getsmashwork.com' ? (
                                    <div>
                                        <Link
                                            to=""
                                            className="btn btn-workahub_blog"
                                            onClick={() => {
                                                window.location.replace(
                                                    'https://www.blog.samstapontheshoulder.com',
                                                );
                                            }}
                                        >
                                            {t('dashboard.blog')}
                                        </Link>
                                    </div>
                                ) : (
                                    wow != 'joinworkahub.com' && (
                                        <div>
                                            <Link
                                                to={`${
                                                    localStorage.getItem('language') == null ||
                                                    localStorage.getItem('language') == undefined
                                                        ? '/en'
                                                        : `/${localStorage.getItem('language')}`
                                                }/blog`}
                                                className="btn btn-workahub_blog"
                                            >
                                                {t('dashboard.blog')}
                                            </Link>
                                        </div>
                                    )
                                )}
                            </div>
                            {/* <div><Link to="/about" className="btn btn-workahub_about">About</Link></div> */}
                        </div>
                    </div>
                </div>
                <div style={{ borderTop: '1px solid #ddd' }} />

                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        marginTop: '20px',
                    }}
                    className="flex-column flex-md-row justify-content-md-between gap-3 align-items-center px-3"
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginRight: '12px' }}>
                            <Link
                                className="text-decoration-none text-dark"
                                to={
                                    localStorage.getItem('language') == null ||
                                    localStorage.getItem('language') == undefined
                                        ? '/en'
                                        : `/${localStorage.getItem('language')}/privacy-policy`
                                }
                            >
                                Privacy Policy
                            </Link>
                            {/* <a
                                className="nav-link sign_in"
                                href={
                                    (localStorage.getItem('language') == null ||
                                    localStorage.getItem('language') == undefined
                                        ? '/en'
                                        : '/' + localStorage.getItem('language')) +
                                    '/privacy-policy'
                                }
                            >
                                Privacy Policy
                            </a> */}
                        </p>

                        <p style={{ marginRight: '12px' }}>Terms of conditions</p>
                        <p style={{ marginRight: '16px' }}>Cookies Policies</p>
                        <div>
                            <LanguageSelection />
                        </div>
                    </div>
                    <div>
                        <div className="d-flex flex-nowrap">
                            <Button target="_blank" href="https://www.instagram.com/workahub/">
                                <InstagramIcon className="icon_style" />
                            </Button>
                            <Button target="_blank" href="http://www.twitter.com/">
                                <TwitterIcon className="icon_style" />
                            </Button>
                            <Button
                                target="_blank"
                                href="https://www.facebook.com/Workahub-106806301628257/"
                            >
                                <FacebookIcon className="icon_style" />
                            </Button>
                            <Button
                                target="_blank"
                                href="https://www.linkedin.com/company/workahub/"
                            >
                                <LinkedInIcon className="icon_style" />
                            </Button>
                        </div>
                    </div>
                </div>
                <p className="reserved" style={{ textAlign: 'center' }}>
                    {' '}
                    Made with {love} in Austin and India © 2022 S7 Works LLC DBA Workahub - All
                    Rights Reserved{' '}
                    {/* <Link to="/termsofservice">
                        <span style={{ cursor: 'pointer', marginLeft: '32px' }}>
                        {' '}
                        Terms of Services
                        </span>
                    </Link> */}
                </p>
                <div className="my-2 text-center" style={{ fontSize: '17px' }}>
                    Call, Text, or Whatsapp &nbsp; +1 (512) 991-4200
                </div>
            </div>
        </div>
    );
    
}

export default Subscribe;
 