/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-key */
import { createPagination } from './pagination.util';
import React from 'react';
import { IconButton } from '@material-ui/core';
import '../../Styles/paginationDemo.scss';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

interface PaginationComponentProps {
    currentOffset: number;
    totalArticles: number;
    articlesPerPage: number;
    // eslint-disable-next-line no-unused-vars
    onHandleClick: (pageNumber: number) => void;
}

export const PaginationComponentDemo = ({
    currentOffset,
    totalArticles,
    articlesPerPage,
    onHandleClick,
}: PaginationComponentProps) => {
    const numberOfPages = Math.ceil(totalArticles / articlesPerPage);
    const [currentPage, setCurrentPage] = React.useState(currentOffset);

    const { pagination } = createPagination({
        numberOfPages,
        numberOfButtons: 5,
        currentPage,
    });

    const handleClick = (page: number) => {
        setCurrentPage(page);
        onHandleClick(page);
    };

    return (
        <div className="paginationDemo">
            <div>
                <IconButton
                    className="iconButton prev-icon-hover"
                    style={{ color: '#000000', paddingLeft: 0 }}
                    disabled={pagination[0] === currentPage}
                    onClick={handleClick.bind(null, currentPage - 1)}
                >
                    <ArrowBackIcon />
                    <span> Previous </span>
                </IconButton>
            </div>
            <ul>
                {pagination[0] === 1 ? <></> : <li>...</li>}
                {pagination.map((page) => (
                    <li
                        key={page}
                        className={`${currentPage === page && 'active'}`}
                        onClick={handleClick.bind(null, page)}
                    >
                        {page}
                    </li>
                ))}
                {pagination[pagination.length - 1] === numberOfPages ? <></> : <li>...</li>}
            </ul>
            <div>
                <IconButton
                    className="iconButton prev-icon-hover"
                    style={{ color: '#000000', paddingRight: 0 }}
                    disabled={pagination.reverse()[0] === currentPage}
                    onClick={handleClick.bind(null, currentPage + 1)}
                >
                    <span> Next </span>
                    <ArrowForwardIcon />
                </IconButton>
            </div>
        </div>
    );
};
