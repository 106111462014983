/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/jsx-key */
import React, { lazy, Suspense, useEffect, useState } from 'react';
import Navbar from '../Navbar';
import '../../Styles/Dashboard.scss';
import { Link, useLocation } from 'react-router-dom';
import '../../Styles/Blog.scss';
import BlogCards from './BlogCard';
import { BulletList } from 'react-content-loader';
import { PaginationComponent } from '../PaginationComponent/PaginationComponent';
import ContentLoader from 'react-content-loader';
import { GetPaginatedBlogs } from '../../services/blog-service';
import _ from 'lodash';
import { useQuery } from '@apollo/client';

const BlogListLoading = () => (
    <ContentLoader viewBox="0 0 500 475" height={475} width={500}>
        <circle cx="70.2" cy="73.2" r="41.3" />
        <rect x="129.9" y="29.5" width="125.5" height="17" />
        <rect x="129.9" y="64.7" width="296" height="17" />
        <rect x="129.9" y="97.8" width="253.5" height="17" />
        <rect x="129.9" y="132.3" width="212.5" height="17" />

        <circle cx="70.7" cy="243.5" r="41.3" />
        <rect x="130.4" y="199.9" width="125.5" height="17" />
        <rect x="130.4" y="235" width="296" height="17" />
        <rect x="130.4" y="268.2" width="253.5" height="17" />
        <rect x="130.4" y="302.6" width="212.5" height="17" />

        <circle cx="70.7" cy="412.7" r="41.3" />
        <rect x="130.4" y="369" width="125.5" height="17" />
        <rect x="130.4" y="404.2" width="296" height="17" />
        <rect x="130.4" y="437.3" width="253.5" height="17" />
        <rect x="130.4" y="471.8" width="212.5" height="17" />
    </ContentLoader>
);

const Subscribe = lazy(() => {
    return Promise.all([
        import('../Subscribe'),
        new Promise((resolve) => setTimeout(resolve, 1200)),
    ]).then(([moduleExports]) => moduleExports);
});

export const Banner = (props: { appname: string }) => {
    return (
        <div style={{ margin: 10 }}>
            <div
                style={{
                    backgroundImage: `url(${'/assets/halfbanner.svg'})`,
                    backgroundSize: '50%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right',
                }}
                className="getStarted"
            >
                <div className="getStartedContainer">
                    <p className="textGet" style={{ marginLeft: '10%' }}>
                        Get Started with{' '}
                    </p>
                    <p className="textGet2" style={{ marginLeft: '10%' }}>
                        {props.appname == 'joinworkahub.com' ? (
                            <span>workahub</span>
                        ) : props.appname == 'getsmashwork.com' ||
                          props.appname == 'localhost:3000' ? (
                            <span>Smashwork</span>
                        ) : (
                            <span style={{ fontSize: '28px', fontWeight: 800 }}>
                                Sam's Tap On The Shoulder
                            </span>
                        )}
                    </p>
                </div>

                <Link
                    className="link_btn free btnTextGet"
                    style={{
                        display: 'flex',
                        alignSelf: 'left',
                        marginLeft: '10%',
                        textDecoration: 'none',
                    }}
                    to={`${
                        localStorage.getItem('language') == null ||
                        localStorage.getItem('language') == undefined
                            ? '/en'
                            : `/${localStorage.getItem('language')}`
                    }/signup`}
                >
                    <button
                        type="button"
                        className="free free_btn2 btn2"
                        //  style={{ padding: "5px", fontWeight: 'bold', height: '40px', width: '200px', textAlign: 'center' }}
                    >
                        GET STARTED
                    </button>
                </Link>
            </div>
        </div>
    );
};

const BaseLists = ({
    startPage,
    limit,
    totalArticles,
}: {
    startPage: string;
    limit: string;
    totalArticles: number;
}) => {
    const variables = {
        start: (_.toNumber(startPage) - 1) * _.toNumber(limit),
        limit: _.toNumber(limit),
    };
    const { data, error, loading, fetchMore } = useQuery(GetPaginatedBlogs, {
        variables,
    });

    const handlePageClick = (selectedPage: number) => {
        fetchMore({
            variables: {
                start: (selectedPage - 1) * variables.limit,
                limit: variables.limit,
            },
            updateQuery: (previousResult: any, { fetchMoreResult }) => {
                return fetchMoreResult.blogs.length ? fetchMoreResult : previousResult;
            },
        });
    };

    if (loading || error) {
        return <BlogListLoading />;
    }

    const url = window.location.href;

    let appNameURL = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
    console.log('appNameURL', appNameURL);

    return (
        <div className="bloglists">
            <Navbar />

            <h1 className="bloglists_title">Workahub Blogs</h1>
            <hr />
            <h2 className="bloglists_trending"></h2>
            {data.blogs.length > 0 ? (
                data.blogs.map((blog: any) => {
                    return (
                        <BlogCards
                            id={blog.id}
                            key={blog.id}
                            title={blog.blogtitle}
                            content={blog.blogcontent}
                            author={blog.Author}
                            date={blog.published_at.slice(0, 10)}
                            image={`https://dfed7k4hwgv3t.cloudfront.net${_.get(
                                blog,
                                'featuredImage[0].url',
                            )}`}
                            category={_.get(blog, 'categories[0].categoryName')}
                        />
                    );
                })
            ) : (
                <BulletList />
            )}
            <PaginationComponent
                currentOffset={_.toNumber(startPage)}
                articlesPerPage={variables.limit}
                totalArticles={totalArticles}
                onHandleClick={handlePageClick}
            />

            <div className="blog_banner">
                <Banner appname={appNameURL} />
            </div>

            <div>
                <Suspense fallback={<BulletList />}>
                    <Subscribe />
                </Suspense>
            </div>
        </div>
    );
};

const BlogLists = () => {
    const query = new URLSearchParams(useLocation().search);
    const startPage = query.get('start') ?? '1';
    const limit = query.get('limit') ?? '4';
    const [data, setData] = useState<any>('');
    const fetchBlogs = () => {
        if (data == '') {
            fetch('https://dfed7k4hwgv3t.cloudfront.net/api/blogs?pagination[start]=0&populate=*', {
                method: 'GET',
            })
                .then((res) => res.json())
                .then((dataa) => {
                    console.log(dataa);
                    setData(dataa);
                });
        }
    };
    useEffect(fetchBlogs, []);
    if (data == '') {
        return <BlogListLoading />;
    }
    return <BaseLists startPage={startPage} limit={limit} totalArticles={data.length} />;
};

export default BlogLists;
