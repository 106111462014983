import React, { useState } from 'react';
import Navbar from './Navbar';
import Subscribe from './Subscribe';

const Support = () => {

    const [phoneNumber, SetPhoneNumber] = useState('')
    const [message, SetMessage] = useState('')
    const [email, SetEmail] = useState('')

    return (
        <div>
            <Navbar />
            <div style={{marginTop: '120px'}}>
                <h2>If you need support, please enter your Phone number</h2>
                <input type='tel' placeholder='Phone number' onChange={(e) => { SetReportText(e.target.value) }} style={{marginTop: '10px', width: '290px', height: '45px'}}/>
                <br />
                <textarea type='text' placeholder='Message' onChange={(e) => { SetMessage(e.target.value) }} style={{marginTop: '10px', width:"290px", height: '70px'}}/>
                <br />
                {/* <h2>Email</h2> */}
                <input type='email' placeholder='Enter Email' onChange={(e) => { SetEmail(e.target.value) }} style={{marginTop: '10px', width: '290px', height: '45px'}}/>
                <br />

                <button style={{color: 'white', backgroundColor: "#684d94", border: 'none', borderRadius: '4px', padding: '4px 12px', marginTop: '20px'}}>Submit</button>
            </div>
            <Subscribe />
        </div >

    )
}

export default Support