import React, { useState, useEffect } from 'react';
import '../../Styles/siginstyles.scss';
import { Link } from 'react-router-dom';
import LoginImage from './loginImage';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export default function Organization_magicLink() {
    const [appName, setAppName] = useState('');
    const [email, setEmail] = useState('');
    const [orgname, setOrgname] = useState('');
    const [magicLinkToken, setMagicLinkToken] = useState('');
    const [ipAddress, setIpAdress] = useState('');

    const [resendEmail, setResendEmail] = useState(false);
    const [showEmailSent, setShowEmailSent] = useState(false);

    useEffect(() => {
        let domainName = window.location.href;
        let appNameURL = domainName.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
        const res = fetch('https://api.ipify.org?format=json').then((response) => response.json()).then((data) => {
            setIpAdress(data.ip);
        })
        setTimeout(() => {
            setAppName(appNameURL);
        }, 100);


        let params = new URL(document.location).searchParams;
        let email = params.get('email');
        setEmail(email);

    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (orgname == '' || email == '' || appName == '') {
            alert('Please fill all the fields');
        } else {
            let domain_name;

            if (appName == 'joinworkahub.com') {
                domain_name = 'joinworkahub';
            } else if(appName=='getsmashwork.com') {
                domain_name = "getsmashwork";
            } else {
                domain_name = 'samsTaponShoulder';
            }

            fetch('https://api.trypadlock.com/generator/email_register_magiclink', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    org_name: orgname,
                    email: email,
                    application: 'workahub',
                    domain_name: domain_name,
                    ip_address: ipAddress,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    let token = data.access_token;
                    setMagicLinkToken(token);
                    setShowEmailSent(true);
                })
                .catch((err) => {
                    console.log('Error in creating Magic Link', err);
                });
        }
    };

    return (
        <div style={{ backgroundColor: '#F3F4F8', height: '100vh' }}>
            <div className="row m-0">
                <div className="col-md-6 no-padding image_hide">
                    <LoginImage appname={appName} />
                </div>
                <div className="col-md-6">
                    <div className="row m-0">
                        <div className="col-md-8 offset-md-2">
                            {!showEmailSent ? (
                                <div className="make_desktop">
                                    {appName == 'joinworkahub.com' ? (
                                        <img
                                            className="image_hub work_img"
                                            style={{ height: 26 }}
                                            src={'/assets/workahub.svg'}
                                        />
                                    ) : appName == 'getsmashwork.com' ||
                                      appName == 'localhost:3000' ? (
                                        <img
                                            className="image_hub work_img"
                                            style={{ height: 31 }}
                                            src={'/assets/SmashworkLogo.svg'}
                                        />
                                    ) : (
                                        <img
                                            className=""
                                            style={{ height: 68 }}
                                            src={'/assets/newSamtap_logo.png'}
                                        />
                                    )}

                                    <h1
                                        className="email_text text-align"
                                        style={{ margin: '8px 0 55px 0', textAlign: 'center' }}
                                    >
                                        Sign up
                                    </h1>
                                    {/* <form className="form_view"> */}
                                    <h4 className="email_text">Organization Name</h4>
                                    <div className="inner-addon left-addon input_control">
                                        <img
                                            className="glyphicon glyphicon-user"
                                            style={{ top: -4 }}
                                            src={'/assets/webimages/OraganisationIcon.webp'}
                                        />

                                        <input
                                            type="organization"
                                            id="org"
                                            name="organization"
                                            className="form-control"
                                            placeholder="Organization Name"
                                            onChange={(e) => {
                                                setOrgname(e.target.value);
                                            }}
                                            noValidate
                                            data-hj-allow
                                        />
                                        {/* {errors.email.length >= 0 && (
                                                <div className="error"> {errors.email} </div>
                                            )} */}
                                        {/* { this.state.msg == "User Already Exist!" &&  <div className='error'> {this.state.msg} </div> } */}
                                        {/* <div className="error"> {this.state.msg} </div> */}
                                    </div>

                                    {/* <button name = 'submit' onClick={this.handleSubmit} type="submit" className="btn btn_submit">Confirm</button> */}

                                    <button
                                        className="btn  btn_submit btn-size"
                                        onClick={(e) => {
                                            handleSubmit(e);
                                        }}
                                    >
                                        Continue
                                    </button>

                                    {/* </form> */}
                                </div>
                            ) : (
                                <div
                                    style={{
                                        height: '75vh',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div style={{}}>
                                        <div>
                                            <img
                                                src={
                                                    resendEmail
                                                        ? '/assets/Send.png'
                                                        : '/assets/email.png'
                                                }
                                                alt="email"
                                                style={{ height: '120px', width: 'auto' }}
                                            />
                                        </div>
                                        <div>
                                            <h2 style={{ fontSize: '30px', fontWeight: 'bold' }}>
                                                {resendEmail
                                                    ? 'Resent successful!'
                                                    : 'Check Your Email!'}
                                            </h2>
                                            <p
                                                style={{
                                                    marginTop: '10px',
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    textAlign: 'center',
                                                    color: '#8692A6',
                                                }}
                                            >
                                                {resendEmail
                                                    ? `Link has been successfully re-shared on the ${email}. Please check your spam folder`
                                                    : `Please click the link shared to ${email} to login`}
                                            </p>
                                        </div>
                                        <div style={{ marginTop: '30px' }}>
                                            <button
                                                style={{
                                                    border: '1px solid #D2D2D2',
                                                    padding: '5px 35px',
                                                    borderRadius: '3px',
                                                    margin: '10px 0px',
                                                }}
                                                onClick={(e) => {
                                                    handleSubmit(e);
                                                    setResendEmail(true);
                                                }}
                                            >
                                                Resend It
                                            </button>
                                        </div>
                                        {resendEmail ? (
                                            <div>
                                                <p style={{ color: '#8692A6' }}>
                                                    Not able to login? Please email us at{' '}
                                                    <span style={{ color: 'blue' }}>
                                                        support@workahub.com
                                                    </span>
                                                </p>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        textAlign: 'center',
                                                        margin: '20px 0px',
                                                    }}
                                                >
                                                    OR
                                                </div>
                                                <div>
                                                    <button
                                                        style={{
                                                            border: 'none',
                                                            padding: '10px 45px',
                                                            borderRadius: '3px',
                                                            backgroundColor: '#684D94',
                                                            color: '#fff',
                                                        }}
                                                        onClick={() => {
                                                            history.back();
                                                        }}
                                                    >
                                                        Login via Password
                                                    </button>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
