/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/jsx-key */
import React from 'react';
import '../../Styles/Blog.scss';
import { Chip } from '@material-ui/core';

interface TopCategoryProps {
    selectedList: string[];
    updateList: (str: string) => void;
}

export const TopBlogCategory = ({ selectedList, updateList }: TopCategoryProps) => {
    const data: any = [
        'Business',
        'Management',
        'Monitoring',
        'Productivity',
        'Remote work',
        'Security',
        'Time Tracking',
    ];
    const handleClick = (category: string) => {
        updateList(category);
    };

    return (
        <div className="bloglists">
            <div className="container">
                <div className="categorySearch">Top searched categories</div>
                <div className="row">
                    {data.map((category: any, index: number) => {
                        const color = [
                            '#991B1B',
                            '#065F46',
                            '#92400E',
                            '#1E40AF',
                            '#9D174D',
                            '#065F46',
                            '#92400E',
                        ];
                        return (
                            <div
                                key={index}
                                className="col-4"
                                style={{
                                    textAlign: 'center',
                                    marginTop: 18,
                                    marginBottom: 18,
                                }}
                            >
                                <Chip
                                    className={`${
                                        selectedList.includes(category) && 'chipSelected'
                                    } chipStyle`}
                                    clickable
                                    onClick={() => handleClick(category)}
                                    style={{
                                        color: color[index],
                                        fontFamily: 'Lato',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        maxWidth: 220,
                                        minHeight: 48,
                                        backgroundColor: [
                                            '#FEE2E2',
                                            '#D1FAE5',
                                            '#FEF3C7',
                                            '#DBEAFE',
                                            '#FCE7F3',
                                            '#D1FAE5',
                                            '#FEF3C7',
                                        ][index],
                                    }}
                                    label={category}
                                    color="primary"
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
