import React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './index.scss';
import App from './App';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';

import common_en from './translations/en/common.json';
import common_es from './translations/es/common.json';
import common_pt from './translations/pt/common.json';
import common_sa from './translations/sa/common.json';

import LanguageDetector from 'i18next-browser-languagedetector';
import { HelmetProvider } from 'react-helmet-async';

const options = {
    // order and from where user language should be detected
    order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
    ],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'language',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    cookieOptions: { path: '/', sameSite: 'strict' },
};

let userLang = navigator.language;

const getOSLang = Intl.DateTimeFormat().resolvedOptions().locale;

const lang = getOSLang && getOSLang.split('-');

const url = window.location.href.split('/')[3];

const language =
    !url || (url !== 'en' && url !== 'es' && url !== 'pt' && url !== 'sa') ? lang[0] : url;

const setLang = getOSLang && localStorage.setItem('language', language);

i18next.use(LanguageDetector).init({
    // detection: options,
    interpolation: {
        escapeValue: false,
        format: function (value, format, lng) {
            if (value instanceof Date) return moment(value).format(format);
            return value;
        },
    },
    lng: language,
    resources: {
        en: { common: common_en },
        es: { common: common_es },
        pt: { common: common_pt },
        sa: { common: common_sa },
    },
});

Sentry.init({
    dsn: 'https://369f6683e41943c8a7dde8b6d1753cd2@sentry.internal.joinworkahub.com/6',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});
render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <HelmetProvider>
                <App />
            </HelmetProvider>
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
