/* eslint-disable no-undef */
import React from 'react';
import '../../Styles/siginstyles.scss';

export default class LoginImage extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div
                className="login-bg"
                style={{
                    backgroundImage: `url(${'/assets/webimages/studentimg.webp'})`,
                }}
            >
                <div className="image_color">
                    <div className="row m-0">
                        <div className="col-md-8 offset-md-2">
                            <div className="login-left-content">
                                <h3 className="work_con">
                                    With{' '}
                                    {this.props.appname == 'joinworkahub.com'
                                        ? 'Workahub'
                                        : this.props.appname == 'getsmashwork.com' ||
                                          this.props.appname == 'localhost:3000'
                                        ? 'Smashwork'
                                        : "Sam's Tap On The Shoulder"}
                                    , you can
                                </h3>
                                <ul className="ul_text">
                                    <li>Know in real time who are working</li>
                                    <li>
                                        Check what task a user is doing by reviewing screen shots
                                    </li>
                                    <li> Know which users are idle </li>
                                    <li>Understand what applications users are using</li>
                                    <li>Run reports on the actual work hours</li>
                                    <li>Generate invoices based on the verifiable logged time</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
