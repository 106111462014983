/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import React from 'react';
import '../../Styles/Dashboard.scss';
import '../../Styles/Blog.scss';

export default class BlogCards extends React.Component {
    constructor(props) {
        super(props);
    }

    nextBlog = (id, title, category) => {
        console.log("my title", title)
        window.location.href = `/blog/${category}/${title.split('-').filter((e) => e !== "").join("-")}/${id}`;
    };

    render() {
        console.log(this.props.content);
        return (
            <div className="blogCards">
                <div className="blogImg_container">
                    <img className="blogCards_img" src={this.props?.image} />
                </div>

                <div className="blogCards_info">
                    <div>
                        <h3 className="blogCards_title">{this.props.title}</h3>
                        <h4 className="blogCards_data">
                            <span>{this.props.date}</span>
                            <span style={{ marginLeft: 20 }}>By {this.props.author}</span>
                        </h4>
                        <div className="blogCards_content">
                            <p>{this.props.content}</p>
                        </div>
                    </div>
                    <span style={{ alignSelf: 'flex-end' }}>
                        <button
                            className="blogCards_read"
                            onClick={() =>
                                this.nextBlog(
                                    this.props.id,
                                    this.props.title
                                        .toLowerCase()
                                        .replace(/ /g, '-')
                                        .replace(/[^\w-]+/g, ''),
                                    this.props.category?.toLowerCase(),
                                )
                            }
                        >
                            Read me..
                        </button>
                    </span>
                </div>
            </div>
        );
    }
}
