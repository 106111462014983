/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { lazy, Suspense, useEffect, useState } from 'react';
import Navbar from '../Navbar';
import '../../Styles/Dashboard.scss';
import { Banner } from './BlogLists';
import { List } from 'react-content-loader';
import { FaLinkedin, FaFacebookF, FaTwitter } from 'react-icons/fa';
import { LinearProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import ReactMarkdown from 'react-markdown';
import { BlogMCards } from './BlogMCards';
import axios from 'axios';
import { int } from 'aws-sdk/clients/datapipeline';
import { readingTime } from 'reading-time-estimator';
import { Helmet } from 'react-helmet-async';

const Subscribe = lazy(async () => {
    const [moduleExports] = await Promise.all([
        import('../Subscribe'),
        new Promise((resolve) => setTimeout(resolve, 500)),
    ]);
    return moduleExports;
});

const BlogSingle = () => {
    const params: any = useParams();
    let duplicateFound: boolean = false;
    const [data1, setData1] = useState<any>('');
    const [data, setData] = useState<any>('');
    const [readingStats, setReadingStats] = useState<any>('');
    const fetchBlog = () => {
        if (data1 == '' || data1 == undefined) {
            axios.get('https://workahub-blogs.cdn.prismic.io/api/v2').then((response) => {
                axios
                    .get(
                        `https://workahub-blogs.cdn.prismic.io/api/v2/documents/search?ref=${response.data.refs[0].ref}&q=%5B%5Bany(my.page.uid%2C%5B%22blog${params.id}%22%5D)%5D%5D`,
                    )
                    .then((res) => {
                        if (res.data.results.length === 0) {
                            window.location.href += '/404';
                        } else {
                            let text = '';
                            for (let content of res.data.results[0].data.content) {
                                text += content.text;
                            }
                            setData1(res.data.results[0]);
                            setReadingStats(readingTime(text, 250));
                        }
                    });
                axios
                    .get(
                        `https://workahub-blogs.cdn.prismic.io/api/v2/documents/search?ref=${response.data.refs[0].ref}&page=1&pageSize=5`,
                    )
                    .then((res) => {
                        setData(res.data.results);
                    });
            });
        }
    };

    useEffect(fetchBlog, []);

    const url = window.location.href;

    let wow = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
    return data1 == '' ? (
        <div style={{ margin: 'auto' }}>
            <List />
        </div>
    ) : (
        <div className="blogSingle">
            <Helmet>
                <title>{data1.data.title[0].text}</title>
                <meta name="title" content={data1.data.title[0].text} data-react-helmet="true" />
                <meta
                    name="description"
                    content={data1.data.meta_description[0].text}
                    data-react-helmet="true"
                />
                <meta
                    property="og:image:type"
                    content={
                        data1.data.featuredimage.url.includes('.png') ? 'image/png' : 'image/jpeg'
                    }
                    data-react-helmet="true"
                />
                <meta
                    property="og:image"
                    content={data1.data.featuredimage.url}
                    data-react-helmet="true"
                />
                <link rel="icon" href={data1.data.featuredimage.url} data-react-helmet="true" />
            </Helmet>
            <Navbar appname={wow} />
            <div>
                <div className="blogSingle_main">
                    <div className="blogsingle_mediaIcons">
                        <ul>
                            <li>
                                <LinkedinShareButton
                                    url={window.location.href}
                                    className="Demo__some-network__share-button"
                                >
                                    <FaLinkedin size={30} />
                                </LinkedinShareButton>
                            </li>
                            <li>
                                <TwitterShareButton
                                    url={window.location.href}
                                    title={data1.blogtitle}
                                    className="Demo__some-network__share-button"
                                >
                                    <FaTwitter size={30} />
                                </TwitterShareButton>
                            </li>
                            <li>
                                <FacebookShareButton
                                    url={window.location.href}
                                    quote={data1.blogtitle}
                                    className="Demo__some-network__share-button"
                                >
                                    <FaFacebookF size={32} />
                                </FacebookShareButton>
                            </li>
                        </ul>
                    </div>
                    <div className="blogSingle_contentSection">
                        {data1.data.featuredimage == undefined ? (
                            <div></div>
                        ) : (
                            // eslint-disable-next-line jsx-a11y/alt-text
                            <img
                                className="blogsingle_featuredImage"
                                src={data1.data.featuredimage.url}
                                alt={data1.data.featuredimage.alt}
                            />
                        )}

                        <div className="blog_section_single">
                            <div className="single_blog_content px-2 px-md-4">
                                <div className="blogSingle_metadata">
                                    <h4
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: '.875rem',
                                            display: 'flex',
                                            margin: '1rem 0 0.5rem 0',
                                        }}
                                    >
                                        {data1.data.categories.map((categoryElem: any) => (
                                            <div
                                                key={categoryElem.category}
                                                style={{
                                                    color: '#1E90FF',
                                                    marginRight: '0.75rem',
                                                    textDecoration: 'underline',
                                                }}
                                            >
                                                {categoryElem.category.toUpperCase()}
                                            </div>
                                        ))}
                                        <div
                                            style={{
                                                color: '#6B7280',
                                                marginLeft: '1rem',
                                            }}
                                        >
                                            {new Date(
                                                data1.last_publication_date.slice(0, 10),
                                            ).toLocaleDateString('en-us', {
                                                year: 'numeric',
                                                day: 'numeric',
                                                month: 'short',
                                            })}
                                        </div>
                                    </h4>

                                    <div className="blogsingle_title">
                                        {data1.data.title[0].text}
                                    </div>
                                    <div className="author_details_blogsingle">
                                        <div className="author_img">
                                            <img
                                                style={{
                                                    marginTop: '0px',
                                                    width: '50px',
                                                    height: '50px',
                                                    borderRadius: '50%',
                                                }}
                                                src={'/assets/profile.svg'}
                                            />
                                            <div className="blogsingle_detail">
                                                <h3 className="blogsingle_author">
                                                    {data1.data.author}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="blogsingle_read_time">
                                            {readingStats && readingStats.text}
                                        </div>
                                    </div>
                                </div>
                                {data1.data.link_to_media && data1.data.link_to_media.url && (
                                    <iframe
                                        style={{
                                            marginBottom: '16px',
                                            width: '100%',
                                            height: '400px',
                                        }}
                                        src={data1.data.link.url}
                                        title="media"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                    ></iframe>
                                )}
                                {data1.data.content.map((elem: any, idx: int) => (
                                    <div
                                        key={idx}
                                        style={{
                                            marginTop:
                                                elem.text.slice(0, 1) === '!' ||
                                                elem.text.slice(0, 1) === '#' ||
                                                elem.text.slice(0, 2) === '##' ||
                                                elem.text.slice(0, 3) === '###' ||
                                                elem.text.slice(0, 4) === '####'
                                                    ? '2rem'
                                                    : elem.text.slice(0, 2) === '**'
                                                    ? '0.5rem'
                                                    : '',
                                            marginBottom:
                                                elem.text.slice(0, 1) === '!' ||
                                                elem.text.slice(0, 1) === '#' ||
                                                elem.text.slice(0, 2) === '##' ||
                                                elem.text.slice(0, 3) === '###' ||
                                                elem.text.slice(0, 4) === '####'
                                                    ? '0.5rem'
                                                    : elem.text.slice(0, 2) === '**'
                                                    ? '0px'
                                                    : '1.2rem',
                                            textAlign:
                                                elem.text.slice(0, 1) === '!' ||
                                                elem.text.slice(0, 2) === '**' ||
                                                elem.text.slice(0, 1) === '#' ||
                                                elem.text.slice(0, 2) === '##' ||
                                                elem.text.slice(0, 3) === '###' ||
                                                elem.text.slice(0, 4) === '####'
                                                    ? 'left'
                                                    : 'justify',
                                        }}
                                    >
                                        {elem.text.slice(0, 1) === '!' ? (
                                            <img
                                                width="100%"
                                                src={`https://imagestrapi.s3.ap-south-1.amazonaws.com/${
                                                    elem.text.split('/').slice(-1)[0].split(')')[0]
                                                }`}
                                                alt={data1.data.title[0].text}
                                                style={{ marginBottom: '1rem' }}
                                            />
                                        ) : (
                                            <ReactMarkdown className="blogsingle_mainData">
                                                {elem.text}
                                            </ReactMarkdown>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="recent_blogs">
                                <div
                                    style={{
                                        marginBottom: '1rem',
                                        color: '#133063',
                                        fontWeight: '600',
                                        fontSize: '1.25rem',
                                    }}
                                >
                                    Recent Articles
                                </div>
                                {data == undefined || data == '' ? (
                                    <div>No recent articles</div>
                                ) : (
                                    data.map((blog: any, idx: int) => {
                                        if (
                                            blog.uid == `blog${params.id}` ||
                                            (!duplicateFound && idx == 3)
                                        ) {
                                            duplicateFound = true;
                                            return null;
                                        }
                                        return (
                                            // eslint-disable-next-line react/jsx-key
                                            <BlogMCards
                                                id={blog.uid}
                                                key={blog.uid}
                                                title={blog.data.title[0].text}
                                                content={blog.data.meta_description[0].text}
                                                date={blog.last_publication_date.slice(0, 10)}
                                                image={blog.data.featuredimage.url}
                                                category={blog.data.categories
                                                    .map(
                                                        (categoryElem: any) =>
                                                            categoryElem.category,
                                                    )
                                                    .join()}
                                            />
                                        );
                                    })
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blog_banner">
                    <Banner appname={wow} />
                </div>
                <Suspense fallback={<LinearProgress />}>
                    <div style={{ marginLeft: 30 }}>
                        <Subscribe />
                    </div>
                </Suspense>
            </div>
        </div>
    );
};

export default BlogSingle;
