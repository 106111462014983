/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { lazy, Suspense, useState } from 'react';
// import '../Styles/Dashboard.scss';
import Navbar from './Navbar';
import './../Styles/PrivacyPolicy.css';

// const ChooseBetterWay = lazy(() => {
//     return Promise.all([
//         import('./ChooseBetterWay'),
//         new Promise((resolve) => setTimeout(resolve, 3000)),
//     ]).then(([moduleExports]) => moduleExports);
// });

// const Subscribe = lazy(() => {
//     return Promise.all([
//         import('./Subscribe'),
//         new Promise((resolve) => setTimeout(resolve, 4000)),
//     ]).then(([moduleExports]) => moduleExports);
// });

export function PrivacyPolicy() {
    return (
        <>
            <Navbar/>
            <div className="container">
                <h1>PRIVACY NOTICE</h1>
                <br />
                <p>  
                    <b>Last updated September 07, 2022</b>
                </p>
                <br />
                <br />
                <p className="content1">
                    This privacy notice for S7 Works LLC (doing business as Workahub) (
                    <b>"Workahub," "we," "us," or "our"</b>),describes how and why we might collect,
                    store, use, and/or share ("<b>process</b>") your information when you our
                    services ("<b>Services</b>"), such as when you:
                </p>
                <br />
                <ul className="list">
                    <li>
                        Visit our website at <a href="https://joinworkahub.com"><u>https://joinworkahub.com</u></a>,
                        or any website of ours that links to this privacy notice
                    </li>
                    <br />
                    <li>
                        Download and use our mobile application (Workahub), or any other application
                        of ours that links to this privacy notice
                    </li>
                    <br />
                    <li>
                        Engage with us in other related ways, including any sales, marketing, or
                        events
                    </li>
                    <br />
                </ul>
                <p className="">
                    <b>Questions or concerns?</b> Reading this privacy notice will help you
                    understand your privacy rights and choices. If you do not agree with our
                    policies and practices, please do not use our Services. If you still have any
                    questions or concerns, please contact us at compliance@joinworkahub.com.
                </p>
                <br />
                <br />
                <br />
                <h4 className="heading">
                    <b>SUMMARY OF KEY POINTS</b>
                </h4>
                <br />
                <p className="content2">
                    <b>
                        <i>
                            This summary provides key points from our privacy notice, but you can
                            find out more details about any of these topics by clicking the link
                            following each key point or by using our table of contents below to find
                            the section you are looking for. You can also click
                            <a href="#toc"><u> here</u></a> to go directly to our table of
                            contents.
                        </i>
                        <br />
                    </b>
                </p>
                <br />
                <p className="">
                    <b>What personal information do we process?</b> When you visit, use, or navigate
                    our Services, we may process personal information depending on how you interact
                    with Workahub and the Services, the choices you make, and the products and
                    features you use. Click <a href="#pro"><u> here</u></a> to learn more.
                </p>
                <br />
                <p className="">
                    <b>Do we process any sensitive personal information?</b> We do not process
                    sensitive personal information.
                </p>
                <br />
                <p className="">
                    <b>Do we receive any information from third parties? </b> do not receive any
                    information from third parties.
                </p>
                <br />
                <p className="">
                    <b> How do we process your information? </b>We process your information to
                    provide, improve, and administer our Services, communicate with you, for
                    security and fraud prevention, and to comply with law. We may also process your
                    information for other purposes with your consent. We process your information
                    only when we have a valid legal reason to do so. Click
                    <a href="#point2"><u> here</u></a> to learn more.
                </p>
                <br />
                <p className="">
                    <b>
                        In what situations and with which parties do we share personal information?
                    </b>
                    We may share information in specific situations and with specific third parties.
                    Click  <a href="#point4"><u> here</u></a> to learn more.
                </p>
                <br />
                <p className="">
                    <b>How do we keep your information safe?</b> We have organizational and
                    technical processes and procedures in place to protect your personal
                    information. However, no electronic transmission over the internet or
                    information storage technology can be guaranteed to be 100% secure, so we cannot
                    promise or guarantee that hackers, cybercriminals, or other unauthorized third
                    parties will not be able to defeat our security and improperly collect, access,
                    steal, or modify your information. Click <a href="#point8"><u> here</u></a> to
                    learn more.
                </p>
                <br />
                <p className="">
                    <b>What are your rights?</b> Depending on where you are located geographically,
                    the applicable privacy law may mean you have certain rights regarding your
                    personal information. Click <a href="#point10"><u> here</u></a>  to learn more.
                </p>
                <br />
                <p id="toc">
                    <b> How do you exercise your rights?</b> The easiest way to exercise your rights
                    is by filling out our data subject request form available here:
                    joinworkahub.com/data-request, or by contacting us. We will consider and act
                    upon any request in accordance with applicable data protection laws.
                </p>
                <br />
                <p >
                    <b>
                        Want to learn more about what Workahub does with any information we collect?
                    </b>
                    Click <a href="#toc"><u> here</u></a>  to review the notice in full.
                </p>
                <br />
                <h3>TABLE OF CONTENTS</h3>
                <br />
                <span className="link">
                    <a href="#point1"><u>1.WHAT INFORMATION DO WE COLLECT?</u></a>
                    <br />
                    <a href="#point2"><u>2. HOW DO WE PROCESS YOUR INFORMATION?</u></a>
                    <br />
                    <a href="#point3"><u>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</u></a>
                    <br />
                    <a href="#point4"><u>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</u></a>
                    <br />
                    <a href="#point5"><u>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</u></a>
                    <br />
                    <a href="#point6"><u>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</u></a>
                    <br />
                    <a href="#point7"><u>7. HOW LONG DO WE KEEP YOUR INFORMATION?</u></a>
                    <br />
                    <a href="#point8"><u>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</u></a>
                    <br />
                    <a href="#point9"><u>9. DO WE COLLECT INFORMATION FROM MINORS?</u></a>
                    <br />
                    <a href="#point10"><u>10. WHAT ARE YOUR PRIVACY RIGHTS?</u></a>
                    <br />
                    <a href="#point11"><u>11. CONTROLS FOR DO-NOT-TRACK FEATURES</u></a>
                    <br />
                    <a href="#point12"><u>12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</u></a>
                    <br />
                    <a href="#point13" id="point1"><u>13. DO WE MAKE UPDATES TO THIS NOTICE?</u></a>
                    <br />
                    <a href="#point14"><u>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</u></a>
                    <br />
                    <a href="#point15"><u>15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</u></a>
                    <br />
                </span>
                <br />
                <h4> 1.WHAT INFORMATION DO WE COLLECT?</h4>
                <br />
                <p id="pro">
                    <i>
                        <b>In Short:</b> We collect personal information that you provide to us.
                    </i>
                </p>
                <br />
                <p className="">
                    We collect personal information that you voluntarily provide to us when you
                    register on the Services, express an interest in obtaining information about us
                    or our products and Services, when you participate in activities on the
                    Services, or otherwise when you contact us.
                </p>
                <br />
                <p >
                    <b> Personal Information Provided by You.</b>The personal information that we
                    collect depends on the context of your interactions with us and the Services,
                    the choices you make, and the products and features you use. The personal
                    information we collect may include the following:
                </p>
                <br />
                <ul className="list">
                    <li>names</li>
                    <br />
                    <li>phone numbers</li>
                    <br />
                    <li>email addresses</li>
                    <br />
                    <li>usernames</li>
                    <br />
                    <li>passwords</li>
                    <br />
                    <li>contact preferences</li>
                    <br />
                    <li>billing addresses</li>
                    <br />
                    <li>debit/credit card numbers</li>
                    <br />
                </ul>
                <br />
                <span className="">
                    <b>Sensitive Information.</b> We do not process sensitive information.
                    <br />
                    <br />
                    <b>Payment Data.</b> We may collect data necessary to process your payment if
                    you make purchases, such as your payment instrument number (such as a credit
                    card number), and the security code associated with your payment instrument. All
                    payment data is stored by Stripe. You may find their privacy notice link(s)
                    here: <a href="https://stripe.com/privacy"><u>https://stripe.com/privacy</u></a>. <br />
                    <br />
                    <b>Social Media Login Data.</b> We may provide you with the option to register
                    with us using your existing social media account details, like your Facebook,
                    Twitter, or other social media account. If you choose to register in this way,
                    we will collect the information described in the section called "
                    <a href="#point6"><u>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</u></a>" below. <br />
                    <br />
                    <b>Application Data.</b> If you use our application(s), we also may collect the
                    following information if you choose to provide us with access or permission:
                    <br />
                </span>
                <br />
                <ul className="list">
                    <li>
                        <i>Geolocation Information.</i> We may request access or permission to track
                        location-based information from your mobile device, either continuously or
                        while you are using our mobile application(s), to provide certain
                        location-based services. If you wish to change our access or permissions,
                        you may do so in your device's settings.
                    </li>
                    <br />
                    <li>
                        <i>Mobile Device Access.</i> We may request access or permission to certain
                        features from your mobile device, including your mobile device's microphone,
                        sensors, storage, contacts, and other features. If you wish to change our
                        access or permissions, you may do so in your device's settings.
                    </li>
                    <br />
                    <li>
                        <i> Mobile Device Data.</i> We automatically collect device information
                        (such as your mobile device ID, model, and manufacturer), operating system,
                        version information and system configuration information, device and
                        application identification numbers, browser type and version, hardware model
                        Internet service provider and/or mobile carrier, and Internet Protocol (IP)
                        address (or proxy server). If you are using our application(s), we may also
                        collect information about the phone network associated with your mobile
                        device, your mobile device’s operating system or platform, the type of
                        mobile device you use, your mobile device’s unique device ID, and
                        information about the features of our application(s) you accessed.
                    </li>
                    <br />
                    <li>
                        <i> Push Notifications.</i> We may request to send you push notifications
                        regarding your account or certain features of the application(s). If you
                        wish to opt out from receiving these types of communications, you may turn
                        them off in your device's settings.
                    </li>
                    <br />
                </ul>
                <span className="">
                    This information is primarily needed to maintain the security and operation of
                    our application(s), for troubleshooting, and for our internal analytics and
                    reporting purposes. <br />
                    <br />
                    All personal information that you provide to us must be true, complete, and
                    accurate, and you must notify us of any changes to such personal information.
                    <br />
                    <br /> <b>Information automatically collected</b>
                    <br />
                    <br />
                    <i>
                        <b>In Short:</b> Some information — such as your Internet Protocol (IP)
                        address and/or browser and device characteristics — is collected
                        automatically when you visit our Services.
                    </i>
                    <br />
                    <br /> We automatically collect certain information when you visit, use, or
                    navigate the Services. This information does not reveal your specific identity
                    (like your name or contact information) but may include device and usage
                    information, such as your IP address, browser and device characteristics,
                    operating system, language preferences, referring URLs, device name, country,
                    location, information about how and when you use our Services, and other
                    technical information. This information is primarily needed to maintain the
                    security and operation of our Services, and for our internal analytics and
                    reporting purposes.
                    <br />
                    <br /> Like many businesses, we also collect information through cookies and
                    similar technologies.
                    <br />
                    <br />
                    The information we collect includes:
                    <br />
                    <ul className="list">
                        <li>
                            <i> Log and Usage Data.</i> Log and usage data is service-related,
                            diagnostic, usage, and performance information our servers automatically
                            collect when you access or use our Services and which we record in log
                            files. Depending on how you interact with us, this log data may include
                            your IP address, device information, browser type, and settings and
                            information about your activity in the Services (such as the date/time
                            stamps associated with your usage, pages and files viewed, searches, and
                            other actions you take such as which features you use), device event
                            information (such as system activity, error reports (sometimes called
                            "crash dumps"), and hardware settings).
                        </li>
                        <br />
                        <li>
                            <i> Device Data. </i> We collect device data such as information about
                            your computer, phone, tablet, or other device you use to access the
                            Services. Depending on the device used, this device data may include
                            information such as your IP address (or proxy server), device and
                            application identification numbers, location, browser type, hardware
                            model, Internet service provider and/or mobile carrier, operating
                            system, and system configuration information.
                        </li>
                        <br />
                        <li>
                            <i id="point2"> Location Data. </i> We collect location data such as information
                            about your device's location, which can be either precise or imprecise.
                            How much information we collect depends on the type and settings of the
                            device you use to access the Services. For example, we may use GPS and
                            other technologies to collect geolocation data that tells us your
                            current location (based on your IP address). You can opt out of allowing
                            us to collect this information either by refusing access to the
                            information or by disabling your Location setting on your device.
                            However, if you choose to opt out, you may not be able to use certain
                            aspects of the Services.
                        </li>
                        <br />
                    </ul>
                </span>
                <br />
                <h4 >2. HOW DO WE PROCESS YOUR INFORMATION?</h4>
                <br />
                <span className="">
                    <i>
                        <b>In Short:</b> We process your information to provide, improve, and
                        administer our Services, communicate with you, for security and fraud
                        prevention, and to comply with law. We may also process your information for
                        other purposes with your consent.
                    </i>
                    <br />
                    <br />
                    <b>
                        We process your personal information for a variety of reasons, depending on
                        how you interact with our Services, including:
                    </b>
                    <br />
                    <br />
                    <ul className="list">
                        <li>
                            <b>
                                To facilitate account creation and authentication and otherwise
                                manage user accounts.
                            </b>
                            We may process your information so you can create and log in to your
                            account, as well as keep your account in working order.
                        </li>
                        <br />
                        <li>
                            <b> To request feedback. </b> We may process your information when
                            necessary to request feedback and to contact you about your use of our
                            Services.
                        </li>
                        <br />
                        <li>
                            <b> To send you marketing and promotional communications.</b>We process
                            the personal information you send to us for our marketing purposes, if
                            this is in accordance with your marketing preferences. You can opt out
                            of our marketing emails at any time. For more information, see "
                            <a href="#point10"><u>WHAT ARE YOUR PRIVACY RIGHTS?</u></a>" below).
                        </li>
                        <br />
                        <li>
                            <b> To protect our Services.</b>To protect our Services. We may process
                            your information as part of our efforts to keep our Services safe and
                            secure, including fraud monitoring and prevention.
                        </li>
                        <br />
                        <li>
                            <b> To identify usage trends.</b> We may process information about how
                            you use our Services to better understand how they are being used so we
                            can improve them.
                        </li>
                        <br />
                        <li>
                            <b>
                                To determine the effectiveness of our marketing and promotional
                                campaigns.
                            </b>
                            We may process your information to better understand how to provide
                            marketing and promotional campaigns that are most relevant to you.
                        </li>
                        <br />
                        <li id="point3">
                            <b> To save or protect an individual's vital interest.</b> To save or
                            protect an individual's vital interest. We may process your information
                            when necessary to save or protect an individual’s vital interest, such
                            as to prevent harm.
                        </li>
                        <br />
                    </ul>
                </span>
                <br />
                <h4>3.WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h4>
                <br />
                <span className="">
                    <i>
                        <b>In Short:</b> We only process your personal information when we believe
                        it is necessary and we have a valid legal reason (i.e., legal basis) to do
                        so under applicable law, like with your consent, to comply with laws, to
                        provide you with services to enter into or fulfill our contractual
                        obligations, to protect your rights, or to fulfill our legitimate business
                        interests.
                    </i>
                    <br />
                    <br />
                    <b>
                        <u>If you are located in the EU or UK, this section applies to you.</u>
                    </b>
                    <br />
                    <br />
                    The General Data Protection Regulation (GDPR) and UK GDPR require us to explain
                    the valid legal bases we rely on in order to process your personal information.
                    As such, we may rely on the following legal bases to process your personal
                    information:
                    <br />
                    <br />
                    <ul className="list">
                        <li>
                            <b> Consent.</b> We may process your information if you have given us
                            permission (i.e., consent) to use your personal information for a
                            specific purpose. You can withdraw your consent at any time. Click <a href="#withdrawconsent"><u>
                        here </u>
                    </a>
                            to learn more.
                        </li>
                        <br />
                        <li>
                            <b> Legitimate Interests. </b> We may process your information when we
                            believe it is reasonably necessary to achieve our legitimate business
                            interests and those interests do not outweigh your interests and
                            fundamental rights and freedoms. For example, we may process your
                            personal information for some of the purposes described in order to:
                        </li>
                        <br />
                        <ul className="list">
                            <li>
                                Send users information about special offers and discounts on our
                                products and services
                            </li>
                            <br />
                            <li>
                                Develop and display personalized and relevant advertising content
                                for our users
                            </li>
                            <br />
                            <li>
                                Analyze how our services are used so we can improve them to engage
                                and retain users
                            </li>
                            <br />
                            <li>Support our marketing activities</li>
                            <br />
                            <li>Diagnose problems and/or prevent fraudulent activities</li>
                            <br />
                            <li>
                                Understand how our users use our products and services so we can
                                improve user experience
                            </li>
                            <br />
                        </ul>
                        <li>
                            <b> Legal Obligations.</b> We may process your information where we
                            believe it is necessary for compliance with our legal obligations, such
                            as to cooperate with a law enforcement body or regulatory agency,
                            exercise or defend our legal rights, or disclose your information as
                            evidence in litigation in which we are involved.
                        </li>
                        <br />
                        <li>
                            <b>Vital Interests.</b> We may process your information where we believe
                            it is necessary to protect your vital interests or the vital interests
                            of a third party, such as situations involving potential threats to the
                            safety of any person.
                        </li>
                    </ul>
                    <br />
                    <b>
                        <i>
                            <u>If you are located in Canada, this section applies to you.</u>
                        </i>
                    </b>
                    <br />
                    <br />
                    {/* here */}
                    We may process your information if you have given us specific permission (i.e.,
                    express consent) to use your personal information for a specific purpose, or in
                    situations where your permission can be inferred (i.e., implied consent). You
                    can withdraw your consent at any time. Click <a href="#withdrawconsent"><u>
                        here </u>
                    </a>
                    to learn more.
                    <br />
                    <br />
                    In some exceptional cases, we may be legally permitted under applicable law to
                    process your information without your consent, including, for example:
                    <br />
                    <br />
                    <ul className="list">
                        <li>
                            If collection is clearly in the interests of an individual and consent
                            cannot be obtained in a timely way
                        </li>
                        <br />
                        <li>For investigations and fraud detection and prevention</li>
                        <br />
                        <li>For business transactions provided certain conditions are met</li>
                        <br />
                        <li>
                            If it is contained in a witness statement and the collection is
                            necessary to assess, process, or settle an insurance claim
                        </li>
                        <br />
                        <li>
                            For identifying injured, ill, or deceased persons and communicating with
                            next of kin
                        </li>
                        <br />
                        <li>
                            If we have reasonable grounds to believe an individual has been, is, or
                            may be victim of financial abuse
                        </li>
                        <br />
                        <li>
                            If it is reasonable to expect collection and use with consent would
                            compromise the availability or the accuracy of the information and the
                            collection is reasonable for purposes related to investigating a breach
                            of an agreement or a contravention of the laws of Canada or a province
                        </li>
                        <br />
                        <li>
                            If disclosure is required to comply with a subpoena, warrant, court
                            order, or rules of the court relating to the production of records
                        </li>
                        <br />
                        <li>
                            If it was produced by an individual in the course of their employment,
                            business, or profession and the collection is consistent with the
                            purposes for which the information was produced
                        </li>
                        <br />
                        <li>
                            If the collection is solely for journalistic, artistic, or literary
                            purposes
                        </li>
                        <br />
                        <li id="point4">
                            If the information is publicly available and is specified by the
                            regulations
                        </li>
                        <br />
                    </ul>
                </span>
                <br />
                <h4 >4.WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4>
                <br />
                <span>
                    <i>
                        <b>In Short:</b> We may share information in specific situations described
                        in this section and/or with the following third parties.
                    </i>
                    <br />
                    <br />
                    We may need to share your personal information in the following situations:
                    <br />
                    <br />
                    <ul className="list">
                        <li>
                            <b>Business Transfers.</b> We may share or transfer your information in
                            connection with, or during negotiations of, any merger, sale of company
                            assets, financing, or acquisition of all or a portion of our business to
                            another company.
                        </li>
                        <br />
                        <li><span id="point5">
                            <b>When we use Google Maps Platform APIs.</b> We may share your information
                            with certain Google Maps Platform APIs (e.g., Google Maps API, Places
                            API). To find out more about Google’s Privacy Policy, please refer to
                            this{' '}
                            <a href="https://policies.google.com/privacy">
                                <u>link</u>
                            </a>
                            . We use certain Google Maps Platform APIs to retrieve certain
                            information when you make location-specific requests. This includes:
                            user's location; and other similar information. A full list of what we
                            use information for can be found in this section and in the previous
                            section titled "
                            <a href="#point2">
                                <u>HOW DO WE PROCESS YOUR INFORMATION?</u>
                            </a>
                            ". We obtain and store on your device ("cache") your location. You may
                            revoke your consent anytime by contacting us at the contact details
                            provided at the end of this document. The Google Maps Platform APIs that
                            we use store and access cookies and other information on your devices.
                            If you are a user currently in the European Economic Area (EU countries,
                            Iceland, Liechtenstein, and Norway) or the United Kingdom, please take a
                            look at our Cookie Notice.</span>
                        </li>
                        <br />
                    </ul>
                </span>
                <br />
                <h4>5.DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
                <br />
                <span id="point6">
                    <i>
                        <b>In Short:</b> We may use cookies and other tracking technologies to
                        collect and store your information.
                    </i>
                    We may use cookies and similar tracking technologies (like web beacons and
                    pixels) to access or store information. Specific information about how we use
                    such technologies and how you can refuse certain cookies is set out in our
                    Cookie Notice.
                </span>
                <br />
                <br />
                <h4>6.HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h4>
                <br />
                <span>
                    <i>
                        <b>In Short:</b>If you choose to register or log in to our services using a
                        social media account, we may have access to certain information about you.
                    </i>
                    <br />
                    <br />
                    Our Services offer you the ability to register and log in using your third-party
                    social media account details (like your Facebook or Twitter logins). Where you
                    choose to do this, we will receive certain profile information about you from
                    your social media provider. The profile information we receive may vary
                    depending on the social media provider concerned, but will often include your
                    name, email address, friends list, and profile picture, as well as other
                    information you choose to make public on such a social media platform.
                    <br />
                    <br /><p id="point7">                    We will use the information we receive only for the purposes that are described
                    in this privacy notice or that are otherwise made clear to you on the relevant
                    Services. Please note that we do not control, and are not responsible for, other
                    uses of your personal information by your third-party social media provider. We
                    recommend that you review their privacy notice to understand how they collect,
                    use, and share your personal information, and how you can set your privacy
                    preferences on their sites and apps.</p>

                </span>
                <br />
                <br />
                <h4>7.HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
                <br />
                <i>
                    <b>In Short:</b> We keep your information for as long as necessary to fulfill
                    the purposes outlined in this privacy notice unless otherwise required by law.
                </i>
                <br />
                <br />
                We will only keep your personal information for as long as it is necessary for the
                purposes set out in this privacy notice, unless a longer retention period is
                required or permitted by law (such as tax, accounting, or other legal requirements).
                No purpose in this notice will require us keeping your personal information for
                longer than the period of time in which users have an account with us.
                <br />
                <br /><p id="point8">
                When we have no ongoing legitimate business need to process your personal
                information, we will either delete or anonymize such information, or, if this is not
                possible (for example, because your personal information has been stored in backup
                archives), then we will securely store your personal information and isolate it from
                any further processing until deletion is possible.</p>
                <br />
                <br />
                <h4 >8.HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>
                <br />
                <span>
                    <i>
                        <b>In Short: </b>We aim to protect your personal information through a
                        system of organizational and technical security measures.
                        <br />
                        <br />
                    </i>
                   <p id="point9"> We have implemented appropriate and reasonable technical and organizational
                    security measures designed to protect the security of any personal information
                    we process. However, despite our safeguards and efforts to secure your
                    information, no electronic transmission over the Internet or information storage
                    technology can be guaranteed to be 100% secure, so we cannot promise or
                    guarantee that hackers, cybercriminals, or other unauthorized third parties will
                    not be able to defeat our security and improperly collect, access, steal, or
                    modify your information. Although we will do our best to protect your personal
                    information, transmission of personal information to and from our Services is at
                    your own risk. You should only access the Services within a secure environment.</p>
                </span>
                <br />
                <br />
                <h4>9. DO WE COLLECT INFORMATION FROM MINORS?</h4>
                <br />
                <span>
                    <i>
                        <b>In Short: </b>We do not knowingly collect data from or market to children
                        under 18 years of age.
                    </i>
                    <br />
                    <br />
                    <p id="point10">
                    We do not knowingly solicit data from or market to children under 18 years of
                    age. By using the Services, you represent that you are at least 18 or that you
                    are the parent or guardian of such a minor and consent to such minor dependent’s
                    use of the Services. If we learn that personal information from users less than
                    18 years of age has been collected, we will deactivate the account and take
                    reasonable measures to promptly delete such data from our records. If you become
                    aware of any data we may have collected from children under age 18, please
                    contact us at compliance@joinworkahub.com.
                    </p>
                </span>
                <br />
                <br />
                <h4>10. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
                <br />
                <span>
                    <i>
                        <b>In Short:</b> In some regions, such as the European Economic Area (EEA),
                        United Kingdom (UK), and Canada, you have rights that allow you greater
                        access to and control over your personal information. You may review,
                        change, or terminate your account at any time.
                    </i>
                    <br />
                    <br />
                    In some regions (like the EEA, UK, and Canada), you have certain rights under
                    applicable data protection laws. These may include the right (i) to request
                    access and obtain a copy of your personal information, (ii) to request
                    rectification or erasure; (iii) to restrict the processing of your personal
                    information; and (iv) if applicable, to data portability. In certain
                    circumstances, you may also have the right to object to the processing of your
                    personal information. You can make such a request by contacting us by using the
                    contact details provided in the section "<a href="#point13"><u>HOW CAN YOU CONTACT US ABOUT THIS
                    NOTICE?</u></a>" below.
                    <br />
                    <br />
                    We will consider and act upon any request in accordance with applicable data
                    protection laws. If you are located in the EEA or UK and you believe we are
                    unlawfully processing your personal information, you also have the right to
                    complain to your local data protection supervisory authority. You can find their
                    contact details here:
                    <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                        <u id="withdrawconsent">
                            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                        </u>
                    </a>
                    <br />
                    <br />
                    <p>
                    If you are located in Switzerland, the contact details for the data protection
                    authorities are available here:
                    <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
                        <u>https://www.edoeb.admin.ch/edoeb/en/home.html</u>
                    </a></p>
                    <br />
                    <br />
                    <u>
                        <b><p>Withdrawing your consent:</p></b>
                    </u>
                    If we are relying on your consent to process your personal information, which
                    may be express and/or implied consent depending on the applicable law, you have
                    the right to withdraw your consent at any time. You can withdraw your consent at
                    any time by contacting us by using the contact details provided in the section "
                    <a href="#point13">
                        <u>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</u>
                    </a>
                    " below or updating your preferences.
                    <br />
                    <br />
                    However, please note that this will not affect the lawfulness of the processing
                    before its withdrawal nor, when applicable law allows, will it affect the
                    processing of your personal information conducted in reliance on lawful
                    processing grounds other than consent.
                    <br />
                    <br />
                    <b>
                        <u>Opting out of marketing and promotional communications:</u>
                    </b>{' '}
                    You can unsubscribe from our marketing and promotional communications at any
                    time by clicking on the unsubscribe link in the emails that we send, replying
                    "STOP" or "UNSUBSCRIBE" to the SMS messages that we send, or by contacting us
                    using the details provided in the section "
                    <a href="#point13">
                        <u>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</u>
                    </a>
                    " below. You will then be removed from the marketing lists. However, we may
                    still communicate with you — for example, to send you service-related messages
                    that are necessary for the administration and use of your account, to respond to
                    service requests, or for other non-marketing purposes.
                    <br />
                    <br />
                    <b>
                        <h4>Account Information</h4>
                    </b>
                    <br />
                    <span>
                        If you would at any time like to review or change the information in your
                        account or terminate your account, you can:
                        <br />
                        <br />
                        <ul className="list">
                            <li>Contact us using the contact information provided.</li>
                        </ul>
                    </span>
                    Upon your request to terminate your account, we will deactivate or delete your
                    account and information from our active databases. However, we may retain some
                    information in our files to prevent fraud, troubleshoot problems, assist with
                    any investigations, enforce our legal terms and/or comply with applicable legal
                    requirements.
                    <br />
                    <br />
                    <b>
                        <u>Cookies and similar technologies:</u>
                    </b>
                    Most Web browsers are set to accept cookies by default. If you prefer, you can
                    usually choose to set your browser to remove cookies and to reject cookies. If
                    you choose to remove cookies or reject cookies, this could affect certain
                    features or services of our Services. To opt out of interest-based advertising
                    by advertisers on our Services visit{' '}
                    <a href="http://www.aboutads.info/choices/" id="point11">
                        <u>http://www.aboutads.info/choices/.</u>
                    </a>
                    <br />
                    <br />
                    If you have questions or comments about your privacy rights, you may email us at
                    compliance@joinworkahub.com.
                </span>
                <br />
                <br />
                <h4>11. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
                <br />
                <span id="point12">
                    Most web browsers and some mobile operating systems and mobile applications
                    include a Do-Not-Track ("DNT") feature or setting you can activate to signal
                    your privacy preference not to have data about your online browsing activities
                    monitored and collected. At this stage no uniform technology standard for
                    recognizing and implementing DNT signals has been finalized. As such, we do not
                    currently respond to DNT browser signals or any other mechanism that
                    automatically communicates your choice not to be tracked online. If a standard
                    for online tracking is adopted that we must follow in the future, we will inform
                    you about that practice in a revised version of this privacy notice.
                </span>
                <br />
                <br />
                <h4>12.DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h4>
                <br />
                <span>
                    <i>
                        <b>In Short: </b> Yes, if you are a resident of California, you are granted
                        specific rights regarding access to your personal information.
                    </i>
                    <br />
                    California Civil Code Section 1798.83, also known as the "Shine The Light" law,
                    permits our users who are California residents to request and obtain from us,
                    once a year and free of charge, information about categories of personal
                    information (if any) we disclosed to third parties for direct marketing purposes
                    and the names and addresses of all third parties with which we shared personal
                    information in the immediately preceding calendar year. If you are a California
                    resident and would like to make such a request, please submit your request in
                    writing to us using the contact information provided below.
                    <br />
                    <br />
                    If you are under 18 years of age, reside in California, and have a registered
                    account with Services, you have the right to request removal of unwanted data
                    that you publicly post on the Services. To request removal of such data, please
                    contact us using the contact information provided below and include the email
                    address associated with your account and a statement that you reside in
                    California. We will make sure the data is not publicly displayed on the
                    Services, but please be aware that the data may not be completely or
                    comprehensively removed from all our systems (e.g., backups, etc.).
                    <br />
                    <br />
                    <b>CCPA Privacy Notice</b>
                    <br />
                    <br />
                    The California Code of Regulations defines a "resident" as:
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(1) every individual who is in the State of California
                    for other than a temporary or transitory purpose and
                    <br />
                    &nbsp;&nbsp;&nbsp;(2) every individual who is domiciled in the State of
                    California who is outside the State of California for a temporary or transitory
                    purpose All other individuals are defined as "non-residents."
                    <br />
                    <br />
                    All other individuals are defined as "non-residents."
                    <br />
                    <br />
                    If this definition of "resident" applies to you, we must adhere to certain
                    rights and obligations regarding your personal information.
                    <br />
                    <br />
                    <b>What categories of personal information do we collect?</b>
                    <br />
                    <br />
                    We have collected the following categories of personal information in the past
                    twelve (12) months:
                    <br />
                    <br />
                    <table className="table1" style={{width:"100%"}}>
                        <tr>
                            <th style={{width:"30%"}}>Category</th>
                            <th style={{width:"60%"}}>Examples</th>
                            <th style={{width:"10%"}}>Collected</th>
                        </tr>
                        <tr>
                            <td style={{padding:"20px"}}>A.Identifiers</td>
                            <td style={{padding:"20px"}}>
                                Contact details, such as real name, alias, postal address, telephone
                                or mobile contact number, unique personal identifier, online
                                identifier, Internet Protocol address, email address, and account
                                name
                            </td>
                            <td style={{padding:"20px"}}>YES</td>
                        </tr>
                        <tr>
                            <td style={{padding:"20px"}}>
                                B.Personal information categories listed in the California Customer
                                Records statute
                            </td>
                            <td style={{padding:"20px"}}>
                                Name, contact information, education, employment, employment
                                history, and financial information
                            </td>
                            <td style={{padding:"20px"}}>YES</td>
                        </tr>
                        <tr>
                            <td style={{padding:"20px"}}>
                                C.Protected classification characteristics under California or
                                federal law
                            </td>
                            <td style={{padding:"20px"}}>Gender and date of birth</td>
                            <td style={{padding:"20px"}}>NO</td>
                        </tr>
                        <tr>
                            <td style={{padding:"20px"}}>D.Commercial information</td>
                            <td style={{padding:"20px"}}>
                                Transaction information, purchase history, financial details, and
                                payment information
                            </td>
                            <td style={{padding:"20px"}}>NO</td>
                        </tr>
                        <tr>
                            <td style={{padding:"20px"}}>E.Biometric information</td>
                            <td style={{padding:"20px"}}>Fingerprints and voiceprints</td>
                            <td style={{padding:"20px"}}>NO</td>
                        </tr>
                        <tr>
                            <td style={{padding:"20px"}}>F.Internet or other similar network activity</td>
                            <td style={{padding:"20px"}}>
                                Browsing history, search history, online behavior, interest data,
                                and interactions with our and other websites, applications, systems,
                                and advertisements
                            </td>
                            <td style={{padding:"20px"}}>YES</td>
                        </tr>
                        <tr>
                            <td style={{padding:"20px"}}>G.Geolocation data</td>
                            <td style={{padding:"20px"}}>Device location</td>
                            <td style={{padding:"20px"}}>YES</td>
                        </tr>
                        <tr>
                            <td style={{padding:"20px"}}>
                                H.Audio, electronic, visual, thermal, olfactory, or similar
                                information
                            </td>
                            <td style={{padding:"20px"}}>
                                Images and audio, video or call recordings created in connection
                                with our business activities
                            </td>
                            <td style={{padding:"20px"}}>YES</td>
                        </tr>
                        <tr>
                            <td style={{padding:"20px"}}>I.Professional or employment-related information</td>
                            <td style={{padding:"20px"}}>
                                Business contact details in order to provide you our Services at a
                                business level or job title, work history, and professional
                                qualifications if you apply for a job with us
                            </td>
                            <td style={{padding:"20px"}}> YES</td>
                        </tr>
                        <tr>
                            <td style={{padding:"20px"}}>J. Education Information</td>
                            <td style={{padding:"20px"}}>Student records and directory information</td>
                            <td style={{padding:"20px"}}>NO</td>
                        </tr>
                        <tr>
                            <td style={{padding:"20px"}}>K. Inferences drawn from other personal information</td>
                            <td style={{padding:"20px"}}>
                                Inferences drawn from any of the collected personal information
                                listed above to create a profile or summary about, for example, an
                                individual’s preferences and characteristics
                            </td>
                            <td style={{padding:"20px"}}>NO</td>
                        </tr>
                    </table>
                    <br />
                    We may also collect other personal information outside of these categories
                    through instances where you interact with us in person, online, or by phone or
                    mail in the context of:
                    <br />
                    <br />
                    <ul className="list">
                        <li>Receiving help through our customer support channels;</li>
                        <br />
                        <li>Participation in customer surveys or contests; and</li>
                        <br />
                        <li>
                            Facilitation in the delivery of our Services and to respond to your
                            inquiries.
                        </li>
                    </ul>
                    <br />
                    <b>How do we use and share your personal information?</b>
                    <br />
                    <br />
                    More information about our data collection and sharing practices can be found in
                    this privacy notice.
                    <br />
                    <br />
                    You may contact us by email at compliance@joinworkahub.com, or by referring to
                    the contact details at the bottom of this document.
                    <br />
                    <br />
                    If you are using an authorized agent to exercise your right to opt out we may
                    deny a request if the authorized agent does not submit proof that they have been
                    validly authorized to act on your behalf.
                    <br />
                    <br />
                    <b>Will your information be shared with anyone else?</b>
                    <br />
                    <br />
                    We may disclose your personal information with our service providers pursuant to
                    a written contract between us and each service provider. Each service provider
                    is a for-profit entity that processes the information on our behalf.
                    <br />
                    <br />
                    We may use your personal information for our own business purposes, such as for
                    undertaking internal research for technological development and demonstration.
                    This is not considered to be "selling" of your personal information.
                    <br />
                    <br />
                    S7 Works LLC has not disclosed or sold any personal information to third parties
                    for a business or commercial purpose in the preceding twelve (12) months. S7
                    Works LLC will not sell personal information in the future belonging to website
                    visitors, users, and other consumers.
                    <br />
                    <br />
                    <b>Your rights with respect to your personal data</b>
                    <br />
                    <br />
                    <u>Right to request deletion of the data — Request to delete</u>
                    <br />
                    <br />
                    You can ask for the deletion of your personal information. If you ask us to
                    delete your personal information, we will respect your request and delete your
                    personal information, subject to certain exceptions provided by law, such as
                    (but not limited to) the exercise by another consumer of his or her right to
                    free speech, our compliance requirements resulting from a legal obligation, or
                    any processing that may be required to protect against illegal activities.
                    <br />
                    <br />
                    <u>Right to be informed — Request to know</u>
                    <br />
                    <br />
                    Depending on the circumstances, you have a right to know:
                    <br />
                    <br />
                    <ul className="list">
                        <li>whether we collect and use your personal information;</li>
                        <br />
                        <li>the categories of personal information that we collect;</li>
                        <br />
                        <li>the purposes for which the collected personal information is used;</li>
                        <br />
                        <li>whether we sell your personal information to third parties;</li>
                        <br />
                        <li>
                            the categories of personal information that we sold or disclosed for a
                            business purpose;
                        </li>
                        <br />
                        <li>
                            the categories of third parties to whom the personal information was
                            sold or disclosed for a business purpose; and
                        </li>
                        <br />
                        <li>
                            the business or commercial purpose for collecting or selling personal
                            information.
                        </li>
                    </ul>
                    In accordance with applicable law, we are not obligated to provide or delete
                    consumer information that is de-identified in response to a consumer request or
                    to re-identify individual data to verify a consumer request.
                    <br />
                    <br />
                    <u>
                        Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
                    </u>
                    <br />
                    <br />
                    We will not discriminate against you if you exercise your privacy rights.
                    <br />
                    <br />
                    <u>Verification process</u>
                    <br />
                    <br />
                    Upon receiving your request, we will need to verify your identity to determine
                    you are the same person about whom we have the information in our system. These
                    verification efforts require us to ask you to provide information so that we can
                    match it with information you have previously provided us. For instance,
                    depending on the type of request you submit, we may ask you to provide certain
                    information so that we can match the information you provide with the
                    information we already have on file, or we may contact you through a
                    communication method (e.g., phone or email) that you have previously provided to
                    us. We may also use other verification methods as the circumstances dictate.
                    <br />
                    <br />
                    We will only use personal information provided in your request to verify your
                    identity or authority to make the request. To the extent possible, we will avoid
                    requesting additional information from you for the purposes of verification.
                    However, if we cannot verify your identity from the information already
                    maintained by us, we may request that you provide additional information for the
                    purposes of verifying your identity and for security or fraud-prevention
                    purposes. We will delete such additionally provided information as soon as we
                    finish verifying you.
                    <br />
                    <br />
                    <u>Other privacy rights</u>
                    <br />
                    <br />
                    <ul className="list">
                        <li>You may object to the processing of your personal information.</li>
                        <br />
                        <li>
                            You may request correction of your personal data if it is incorrect or
                            no longer relevant, or ask to restrict the processing of the
                            information.
                        </li>
                        <br />
                        <li>
                            You can designate an authorized agent to make a request under the CCPA
                            on your behalf. We may deny a request from an authorized agent that does
                            not submit proof that they have been validly authorized to act on your
                            behalf in accordance with the CCPA.
                        </li>
                        <br />
                        <li>
                            You may request to opt out from future selling of your personal
                            information to third parties. Upon receiving an opt-out request, we will
                            act upon the request as soon as feasibly possible, but no later than
                            fifteen (15) days from the date of the request submission.
                        </li>
                    </ul>
                <p id="point13">
                    To exercise these rights, you can contact us by email at
                    compliance@joinworkahub.com, or by referring to the contact details at the
                    bottom of this document. If you have a complaint about how we handle your data,
                    we would like to hear from you.</p>
                </span>
                <br />
                <br />
                <h4 >13. DO WE MAKE UPDATES TO THIS NOTICE?</h4>
                <br />
                <span>
                    <i>
                        <b>In Short:</b> Yes, we will update this notice as necessary to stay
                        compliant with relevant laws.
                    </i>
                    <br />
                    <br /><p id="point14">
                    We may update this privacy notice from time to time. The updated version will be
                    indicated by an updated "Revised" date and the updated version will be effective
                    as soon as it is accessible. If we make material changes to this privacy notice,
                    we may notify you either by prominently posting a notice of such changes or by
                    directly sending you a notification. We encourage you to review this privacy
                    notice frequently to be informed of how we are protecting your information.</p>
                </span>
                <br />
                <br />
                <h4>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>
                <br />
                <span id="point15">
                    If you have questions or comments about this notice, you may email us at
                    compliance@joinworkahub.com or by post to:
                    <br />
                    <br />
                    S7 Works LLC <br />
                    1814 Kingston Lacy Blvd
                    <br /> Pflugerville, TX 78660
                </span>
                <br />
                <br />
                <h4> 15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h4>
                <br />
                <span>
                    Based on the applicable laws of your country, you may have the right to request
                    access to the personal information we collect from you, change that information,
                    or delete it. To request to review, update, or delete your personal information,
                    please visit: joinworkahub.com/data-request. This privacy policy was created
                    using Termly's{' '}
                    <a href="https://termly.io/products/privacy-policy-generator/">
                        <u>Privacy Policy Generator.</u>
                    </a><br/>
                </span>
            </div>
        </>
    );
}
