/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import '../../Styles/Blog.scss';
import _ from 'lodash';
import { ArrowForward } from '@material-ui/icons';

interface BlogMCardsProps {
    id: string;
    title: string;
    content: string;
    date?: string;
    image: string;
    category: string;
}

export const BlogMCards = ({ id, title, content, date, image, category }: BlogMCardsProps) => {
    const nextBlog = (id: string, title: string) => {
        window.location.href = `/en/blog/${id.slice(4, id.length)}/${title
            .split(' ')
            .filter((e) => e !== '')
            .join('-')}`;
    };
    return (
        <div className="blogMCards">
            <div>
                {image == undefined ? (
                    <></>
                ) : (
                    <img
                        className="display_img"
                        src={image}
                        alt={title}
                        onClick={() =>
                            nextBlog(
                                id,
                                title
                                    .toLowerCase()
                                    .replace(/ /g, '-')
                                    .replace(/[^\w-]+/g, ''),
                            )
                        }
                    />
                )}
            </div>
            <div className="maincard_content_container">
                <div>
                    <h4
                        className="maincard_title_recent"
                        onClick={() =>
                            nextBlog(
                                id,
                                title
                                    .toLowerCase()
                                    .replace(/ /g, '-')
                                    .replace(/[^\w-]+/g, ''),
                            )
                        }
                    >
                        {title}
                    </h4>
                    <p className="maincard_content">{content}</p>
                </div>
                <p
                    className="maincard_readbtn"
                    onClick={() =>
                        nextBlog(
                            id,
                            title
                                .toLowerCase()
                                .replace(/ /g, '-')
                                .replace(/[^\w-]+/g, ''),
                        )
                    }
                >
                    Read more <ArrowForward />
                </p>
            </div>
        </div>
    );
};
