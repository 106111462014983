/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { lazy, Suspense, useState } from 'react';
import '../Styles/Dashboard.scss';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { useEffect } from 'react';
import { List } from 'react-content-loader';
// @ts-ignore
import ModalVideo from 'react-modal-video';

import { useTranslation } from 'react-i18next';

const TimeDigital = lazy(() => {
    return Promise.all([
        import('./TimeDigital'),
        new Promise((resolve) => setTimeout(resolve, 500)),
    ]).then(([moduleExports]) => moduleExports);
});

const WhoWorkahub = lazy(() => {
    return Promise.all([
        import('./WhoWorkahub'),
        new Promise((resolve) => setTimeout(resolve, 800)),
    ]).then(([moduleExports]) => moduleExports);
});

const ChooseBetterWay = lazy(() => {
    return Promise.all([
        import('./ChooseBetterWay'),
        new Promise((resolve) => setTimeout(resolve, 1000)),
    ]).then(([moduleExports]) => moduleExports);
});

const Subscribe = lazy(() => {
    return Promise.all([
        import('./Subscribe'),
        new Promise((resolve) => setTimeout(resolve, 1200)),
    ]).then(([moduleExports]) => moduleExports);
});

const RealTimeProof = lazy(() => {
    return Promise.all([
        import('./RealTimeProof'),
        new Promise((resolve) => setTimeout(resolve, 500)),
    ]).then(([moduleExports]) => moduleExports);
});

export const DashBoard = () => {
    const [state, setState] = useState<any>({
        width: window.innerWidth,
        isPlaying: false,
        isModelOpen: false,
        isPageLoaded: false,
    });

    const { t } = useTranslation('common');

    useEffect(() => {
        test();
        setTimeout(() => {
            setState({ width: window.innerWidth, isPageLoaded: true });
        }, 500);
    }, []);

    const url = window.location.href;
    let wow = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];

    const test = () => {
        let infor = localStorage.getItem('names');
        if (!infor) {
            localStorage.setItem('names', JSON.stringify({}));
        }
    };

    return (
        <>
            <Navbar appname={wow} />
            <div className="dashboard_div">
                <>{test()}</>
                {wow == 'joinworkahub.com' ? (
                    <ModalVideo
                        channel="youtube"
                        isOpen={state.isModelOpen}
                        videoId="kWA8eCrwP8w"
                        start
                        onClose={() => setState({ ...state, isModelOpen: false })}
                    />
                ) : null}
                <div className="container mt-0 mx-auto">
                    <div className="container-fluid dashboard-container">
                        {wow == 'joinworkahub.com' ? (
                            <IconButton
                                className="playButton"
                                onClick={() => {
                                    setState({ ...state, isModelOpen: true });
                                }}
                                onMouseOver={() => setState({ ...state, isPlaying: true })}
                                onMouseOut={() => setState({ ...state, isPlaying: false })}
                            >
                                <img
                                    height="75px"
                                    width="75px"
                                    src={`/assets/${state.isPlaying ? 'playon' : 'playoff'}.svg`}
                                />
                            </IconButton>
                        ) : null}
                        <div className=" col-md-5 image_track home_track mt-2">
                            <p
                                className="work_from_home_mobile"
                                style={{ fontSize: '20px', fontWeight: 700 }}
                            >
                                {t('dashboard.wfh')}
                            </p>
                            <p className="work_Track">{t('dashboard.title')}</p>
                            <p className="work_fed">{t('dashboard.subTitle')}</p>
                            <Link
                                className="link_btn"
                                to={
                                    localStorage.getItem('language') == null ||
                                    localStorage.getItem('language') == undefined
                                        ? '/en'
                                        : `/${localStorage.getItem('language')}/signup`
                                }
                            >
                                <button
                                    type="button"
                                    className="btn btn-lg free_btn2 btn2 get_y mb-2"
                                    style={{
                                        minHeight: 'max-content',
                                        minWidth: 'max-content',
                                    }}
                                >
                                    {(localStorage as any).getItem('names').length == 2
                                        ? `${t('dashboard.getStartedButton')}`
                                        : `${t('dashboard.shareInviteButton')}`}
                                </button>
                            </Link>
                        </div>

                        <div className="col-md-7 image_track tracks_y">
                            <>
                                {wow == 'joinworkahub.com' ? (
                                    <IconButton
                                        className="playButtonMobile"
                                        onClick={() => {
                                            setState({ ...state, isModelOpen: true });
                                        }}
                                        onMouseOver={() => setState({ ...state, isPlaying: true })}
                                        onMouseOut={() => setState({ ...state, isPlaying: false })}
                                    >
                                        <img
                                            height="75px"
                                            width="75px"
                                            src={`/assets/${
                                                state.isPlaying ? 'playon' : 'playoff'
                                            }.svg`}
                                        />
                                    </IconButton>
                                ) : null}
                                <div
                                    className="bubbleImage"
                                    role="button"
                                    onClick={() => {
                                        setState({ ...state, isModelOpen: true });
                                    }}
                                >
                                    {state.isPageLoaded ? (
                                        <>
                                            <video className="bubbleVideo" autoPlay loop muted>
                                                <source
                                                    src={'/assets/bubble.mp4'}
                                                    type="video/mp4"
                                                />
                                            </video>
                                            <video
                                                className="bubbleVideoMobile"
                                                autoPlay
                                                loop
                                                muted
                                            >
                                                <source
                                                    src={'/assets/bubbleMobile.mp4'}
                                                    type="video/mp4"
                                                />
                                            </video>
                                        </>
                                    ) : (
                                        <img
                                            className="responsive"
                                            src={'/assets/teamImage.svg'}
                                            srcSet={`
                                    ${'/assets/teamImage.svg'} 607w, 
                                    ${'/assets/teamImage.svg'} 500w `}
                                        />
                                    )}
                                </div>
                            </>
                        </div>
                    </div>

                    <Suspense fallback={<List />}>
                        <RealTimeProof appname={wow} />
                    </Suspense>
                </div>

                <div
                    className="container"
                    style={{
                        padding: '16px',
                        backgroundColor: '#F3F4F6',
                        display: 'flex',
                        marginTop: '16px',
                        marginBottom: '24px',
                    }}
                >
                    <div className="row m-0 p-0 gap-1 gap-md-0">
                        <div className="col-5 col-md-2 mb-2">
                            <img
                                className="logoresponsive"
                                src={'/assets/lorhanlogo.png'}
                                style={{ height: '60%' }}
                                srcSet={`
                                    ${'/assets/lorhanlogo.png'} 607w, 
                                    ${'/assets/lorhanlogo.png'} 500w `}
                            />
                        </div>
                        <div className="col-6 col-md-2 mb-2">
                            <img
                                className="logoresponsive"
                                src={'/assets/Main-Logo2.png'}
                                srcSet={`
                                    ${'/assets/Main-Logo2.png'} 607w, 
                                    ${'/assets/Main-Logo2.png'} 500w `}
                            />
                        </div>
                        <div className="col-5 col-md-3 mb-2">
                            <img
                                className="logoresponsive"
                                src={'/assets/gforcelogo1.png'}
                                srcSet={`
                                    ${'/assets/gforcelogo1.png'} 607w, 
                                    ${'/assets/gforcelogo1.png'} 500w `}
                            />
                        </div>

                        <div className="col-6 col-md-2 mb-2">
                            <img
                                className="logoresponsive"
                                src={'/assets/CG-Logo.png'}
                                srcSet={`
                                    ${'/assets/CG-Logo.png'} 607w, 
                                    ${'/assets/CG-Logo.png'} 500w `}
                            />
                        </div>
                        <div className="d-none d-md-block col-md-3 mb-2">
                            <img
                                className="logoresponsive"
                                src={'/assets/whrr.png'}
                                style={{ height: '120px' }}
                                srcSet={`
                                    ${'/assets/whrr.png'} 607w, 
                                    ${'/assets/whrr.png'} 500w `}
                            />
                        </div>
                    </div>
                </div>

                <Suspense fallback={<></>}>
                    <TimeDigital appname={wow} />
                </Suspense>

                <Suspense fallback={<></>}>
                    <WhoWorkahub appname={wow} />
                </Suspense>

                <Suspense fallback={<></>}>
                    <ChooseBetterWay appname={wow} />
                </Suspense>

                <Suspense fallback={<></>}>
                    <Subscribe />
                </Suspense>
            </div>
        </>
    );
};
