/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import samTapLogo from '../../public/assets/newSSTlogo.png';
//import Drift from 'react-drift-web'
// import Modal from 'react-responsive-modal';
import { workhhubConst } from '../services/constants';
import '../Styles/Navbar.css';
import LanguageSelection from './LanguageSelection';

import { withTranslation } from 'react-i18next';

class Navbar extends React.Component {
    // componentDidMount() {
    //   var infor=localStorage.getItem('names');

    //   if(infor.length==2){
    //     console.log(infor)
    //   }else{
    //     const getTk = localStorage.setItem('names',JSON.stringify({}) );
    //   }

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            textDisplay: false,
            appName: '',
        };
    }

    // // eslint-disable-next-line react/no-deprecated
    // componentDidMount() {
    //     this.Test();
    // }

    componentDidMount() {
        let domainName = window.location.href;
        let appNameURL = domainName.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
        this.setState({ appName: appNameURL });
    }

    Test = async () => {
        let infor = localStorage.getItem('names');
        // console.log(infor, 'yyy');
        if (infor) {
            // console.log(infor);
        } else {
            localStorage.setItem('names', JSON.stringify({}));
        }
    };

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    ToggleButton = () => {
        this.setState({
            textDisplay: !this.state.textDisplay,
        });
    };

    Login = (token, org_name) => {
        fetch('https://api.trypadlock.com/users/check_payment', {
            method: 'GET',
            headers: {
                authorization: `JWT ${token}`,
                // "authorization": 'JWT ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFybmF2LmFncmF3YWxAY29wcGVyZ2F0ZS5pbiIsInVzZXJuYW1lIjoiYXJuYXZhZ3Jhd2FsIiwib3JnX25hbWUiOiJDb3BwZXJnYXRlIiwidXVfaWQiOiIwMjJhMGIxMy1hNTQyLTRkZGUtOTRiMi01NDYzZWJiOTllZDMiLCJwYXNzd29yZCI6IkFkbWluQENvcHBlcmdhdGUxIiwicm9sZSI6Im1hbmFnZXIiLCJmaXJzdG5hbWUiOiJhcm5hdiIsImxhc3RuYW1lIjoiYWdyYXdhbCIsImlhdCI6MTY0MzI4MDUzMH0.U7uPQ6CFZFUsbK2aBGAKvyNQgIyNRu_kCsYqqRWUSrM',
                'Content-Type': 'application/json',
            },
        })
            .then((Response) => {
                if (Response.status === 302) {
                    if (this.state.appName == 'joinworkahub.com') {
                        window.location.href = `https://${org_name}.joinworkahub.com/redirect?token=${token}`;
                    } else if(this.state.appName=='getsmashwork.com') {
                        window.location.href=`https://${org_name}.getsmashwork.com/redirect?token=${token}`;
                    } else {
                        window.location.href = `https://${org_name}.samstapontheshoulder.com/redirect?token=${token}`;
                    }
                    return Response.json();
                } else {
                    // console.log('Response.status', Response.status)
                    return Response.json();
                }
            })
            .then((data) => {
                if (data.free == false) {
                    alert(
                        'Your trial period has expired. Please contact us to renew your subscription.',
                    );
                    // console.log('Access Denied')
                } else {
                    if (this.state.appName == 'joinworkahub.com') {
                        window.location.href = `https://${org_name}.joinworkahub.com/redirect?token=${token}`;
                    } else if(this.state.appName=="getsmashwork.com") {
                        window.location.href = `https://${org_name}.getsmashwork.com/redirect?token=${token}`;
                    } else {
                        window.location.href = `https://${org_name}.samstapontheshoulder.com/redirect?token=${token}`;
                    }
                    // console.log('Access Granted')
                }
                // console.log('checkTrialPeriodFECTH', data);
            })
            .catch((error) => {
                console.log(error);
            });
        // window.location.href = `https://app.joinworkahub.com/redirect?token=${token}`;
        // console.log(`token :${token}`);
    };

    // Login = (org_name, token) => {

    //     fetch('https://api.trypadlock.com/users/check_payment', {
    //         method: 'GET',
    //         headers: {
    //             "authorization": 'JWT ' + token,
    //             'Content-Type': 'application/json',
    //         }
    //     })
    //     .then((response) =>  response.json())
    //     .then((data) => {
    //         if(data.free == false){
    //             alert('Your trial period has expired. Please contact us to renew your subscription.');
    //             console.log('Access Denied')
    //         } else {
    //             fetch(`https://api.trypadlock.com/users/frontendData/${org_name}`, {
    //                 method: 'GET',
    //             })
    //             .then((res) => res.json())
    //             .then((data) => {
    //                 console.log('data SIGN UP', data)
    //                 if(this.state.appName == 'joinworkahub.com'){
    //                     window.location.href = `${data.url}/api/redirect?token=${token}`;
    //                 } else {
    //                     window.location.href = `${data.url}/api/redirect?token=${token}`;
    //                 }
    //             })
    //             .catch((err) => {console.log('something went wrong while SIGN UP', err)})
    //             console.log('Access Granted')
    //         }
    //         console.log('checkTrialPeriodFECTH', data);
    //     })
    //     .catch((error) => {console.log(error)});
    // };

    check = () => {
        for (let key in localStorage.keys()) {
            // console.log(key);
        }
    };

    render() {
        const { t } = this.props;

        const collapsed = this.state.collapsed;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed
            ? 'navbar-toggler navbar-toggler-right collapsed'
            : 'navbar-toggler navbar-toggler-right';

        const getEmail = localStorage.getItem('userEmail');
        localStorage.getItem(getEmail);

        const style = {
            active_link: {
                color: '#684D94',
            },
            active_black_link: {
                color: '#000000',
            },
        };
        return (
            <>
                <nav
                    className="navbar navbar-expand-lg bg-light-app fixed-top"
                    style={{ maxWidth: '100vw' }}
                >
                    <div className="container" style={{ marginTop: 10 }}>
                        <a
                            href="/en/home"
                            style={{
                                color: 'black',
                                fontWeight: '700',
                                textDecoration: 'none',
                                marginRight: 20,
                            }}
                        >
                            {this.state.appName == 'joinworkahub.com' ? (
                                <img
                                    style={{ height: 24, marginLeft: 12 }}
                                    src={'/assets/workahub.svg'}
                                />
                            ) : this.state.appName == 'getsmashwork.com' ||
                              this.state.appName == 'localhost:3000' ? (
                                <img
                                    style={{ height: 31, marginLeft: 12 }}
                                    src={'/assets/SmashworkLogo.svg'}
                                />
                            ) : (
                                <img src={samTapLogo} className="logo" alt="sam tap" />
                            )}
                        </a>

                        {/* <Drift appId="w5zp68iv8acy" /> */}

                        <button
                            onClick={this.toggleNavbar}
                            className={`${classTwo}`}
                            style={{ borderColor: 'white', outline: 'none' }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarResponsive"
                            aria-controls="navbarResponsive"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            {/* <span className="navbar-toggler-icon" style={{color:'#000'}}/> */}
                            {this.state.collapsed ? (
                                <MenuIcon className="icon_nav" />
                            ) : (
                                <CloseIcon className="icon_nav" />
                            )}
                        </button>
                        <div className={`${classOne}`} id="navbarResponsive">
                            <ul className="navbar-nav mr-auto ul_nav">
                                <li className="nav-item">
                                    <NavLink
                                        to={
                                            localStorage.getItem('language') == null ||
                                            localStorage.getItem('language') == undefined
                                                ? '/en'
                                                : `/${localStorage.getItem('language')}/home`
                                        }
                                        activeStyle={style.active_link}
                                        className="link_path"
                                    >
                                        <span>
                                            {' '}
                                            <a className="nav-link sign_in">
                                                {t('dashboard.home')}
                                            </a>
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="nav-item ">
                                    <NavLink
                                        to={
                                            localStorage.getItem('language') == null ||
                                            localStorage.getItem('language') == undefined
                                                ? '/en'
                                                : `/${localStorage.getItem('language')}/features`
                                        }
                                        activeStyle={style.active_link}
                                        className="link_path"
                                    >
                                        <span>
                                            {' '}
                                            <a className="nav-link sign_in">
                                                {t('dashboard.features')}
                                            </a>
                                        </span>
                                    </NavLink>
                                </li>
                                {/* <li className="nav-item ">
                                <NavLink to="/pricing" activeStyle={style.active_link} className="link_path">
                                    <a className="nav-link sign_in">{t('dashboard.pricing')}</a>
                                </NavLink>
                            </li> */}
                                <li className="nav-item ">
                                    <NavLink
                                        to={
                                            localStorage.getItem('language') == null ||
                                            localStorage.getItem('language') == undefined
                                                ? '/en'
                                                : `/${localStorage.getItem('language')}/download`
                                        }
                                        activeStyle={style.active_link}
                                        className="link_path"
                                    >
                                        <span>
                                            {' '}
                                            <a className="nav-link sign_in">
                                                {t('dashboard.download')}
                                            </a>
                                        </span>
                                    </NavLink>
                                </li>
                                {/* <li className="nav-item ">
                <NavLink to='/about' activeStyle={style.active_link} className="link_path"><a className="nav-link sign_in">About</a></NavLink>
              </li> */}
                                {/*this.state.appName != 'joinworkahub.com' ? null : (
                                    <li className="nav-item ">
                                        <NavLink
                                            to={
                                                (localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : '/' + localStorage.getItem('language')) +
                                                '/blog'
                                            }
                                            activeStyle={style.active_link}
                                            className="link_path"
                                        >
                                            <a className="nav-link sign_in">
                                                {t('dashboard.blogs')}
                                            </a>
                                        </NavLink>
                                    </li>
                                )*/}
                                {this.state.appName != 'joinworkahub.com' &&
                                this.state.appName != 'localhost:3000' &&
                                this.state.appName == 'getsmashwork.com' ? (
                                    <li className="nav-item ">
                                        <NavLink
                                            to=""
                                            activeStyle={style.active_black_link}
                                            className="link_path"
                                            onClick={() => {
                                                window.location.replace(
                                                    'https://www.blog.samstapontheshoulder.com',
                                                );
                                            }}
                                        >
                                            <span>
                                                <a className="nav-link sign_in">
                                                    {t('dashboard.blogs')}
                                                </a>
                                            </span>
                                        </NavLink>
                                    </li>
                                ) : (
                                    this.state.appName != 'joinworkahub.com' && (
                                        <li className="nav-item ">
                                            <NavLink
                                                to={`${
                                                    localStorage.getItem('language') == null ||
                                                    localStorage.getItem('language') == undefined
                                                        ? '/en'
                                                        : `/${localStorage.getItem('language')}`
                                                }/blog`}
                                                activeStyle={style.active_link}
                                                className="link_path"
                                            >
                                                <span>
                                                    <a className="nav-link sign_in">
                                                        {t('dashboard.blog')}
                                                    </a>
                                                </span>
                                            </NavLink>
                                        </li>
                                    )
                                )}
                            </ul>
                            <form className="navbar-nav form-inline my-2 my-lg-0">
                                <ul className="navbar-nav mr-auto">
                                    {/* <li>
                                   <LanguageSelection />
                                </li> */}
                                    {localStorage.getItem('names').length == 2 && (
                                        <li className="nav-item text-left">
                                            {/* <a
                                                className="nav-link"
                                                href="#"
                                                style={{ marginTop: '5px' }}
                                            > */}
                                            <span
                                                className="nav-link"
                                                href="#"
                                                style={{ marginTop: '5px' }}
                                            >
                                                <NavLink
                                                    activeStyle={style.active_link}
                                                    className="link_path sign_in signInhover"
                                                    to={
                                                        localStorage.getItem('language') == null ||
                                                        localStorage.getItem('language') ==
                                                            undefined
                                                            ? '/en'
                                                            : `/${localStorage.getItem(
                                                                  'language',
                                                              )}/signin`
                                                    }
                                                >
                                                    {t('dashboard.subscribeModule.signin')}
                                                </NavLink>
                                            </span>
                                            {/* </a> */}
                                        </li>
                                    )}

                                    {localStorage.getItem('names').length == 2 && (
                                        <Link
                                            style={{ margin: 5 }}
                                            className="link_btn"
                                            to={
                                                this.state.appName != 'joinworkahub.com' &&
                                                this.state.appName != 'localhost:3000' &&
                                                this.state.appName == 'getsmashwork.com'
                                                    ? ''
                                                    : localStorage.getItem('language') == null ||
                                                      localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}/signup`
                                            }
                                            onClick={() => {
                                                if (
                                                    this.state.appName != 'joinworkahub.com' &&
                                                    this.state.appName != 'localhost:3000' &&
                                                    this.state.appName == 'getsmashwork.com'
                                                )
                                                    window.location.replace(
                                                        'https://calendly.com/saumyagarg/30min',
                                                    );
                                            }}
                                        >
                                            <button
                                                style={{ fontWeight: 'bold' }}
                                                type="button"
                                                className="btn btn-lg free_btn btn2"
                                            >
                                                {t('dashboard.getStartedButton')}
                                            </button>
                                        </Link>
                                    )}

                                    {localStorage.getItem('names').length == 2 ? null : (
                                        <Dropdown
                                            className="btn2"
                                            color="white"
                                            style={{
                                                margin: 5,
                                                color: 'white',
                                                backgroundColor: '#684D94',
                                                border: '1px solid #684D94',
                                                borderRadius: 5,
                                                marginRight: 10,
                                            }}
                                            isOpen={this.state.textDisplay}
                                            toggle={this.ToggleButton}
                                        >
                                            <DropdownToggle
                                                className="btn2"
                                                color="white"
                                                style={{
                                                    boxShadow: 'none',
                                                    color: 'white',
                                                    fontWeight: 'bold',
                                                    padding: '6px 30px',
                                                }}
                                                caret
                                            >
                                                <span style={{ margin: '0px 5px' }}>LAUNCH</span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {Object.entries(
                                                    JSON.parse(localStorage.getItem('names')),
                                                ).map((key, index) => {
                                                    return (
                                                        <DropdownItem
                                                            style={{
                                                                backgroundColor: 'white',
                                                                color: 'black',
                                                            }}
                                                            key={`${key[0]}${index}`}
                                                        >
                                                            <option
                                                                style={{
                                                                    backgroundColor: 'white',
                                                                    boxShadow: '1px solid white',
                                                                }}
                                                                onClick={() =>
                                                                    this.Login(key[1], key[0])
                                                                }
                                                            >
                                                                {key[0]}
                                                            </option>
                                                        </DropdownItem>
                                                    );
                                                })}
                                                <DropdownItem divider />
                                                <DropdownItem style={{ backgroundColor: 'white' }}>
                                                    <NavLink
                                                        className="link_path sign_in"
                                                        style={{ fontSize: 14, boxShadow: 'none' }}
                                                        to={
                                                            localStorage.getItem('language') ==
                                                                null ||
                                                            localStorage.getItem('language') ==
                                                                undefined
                                                                ? '/en'
                                                                : `/${localStorage.getItem(
                                                                      'language',
                                                                  )}/signup`
                                                        }
                                                    >
                                                        Create a new workspace{' '}
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem style={{ backgroundColor: 'white' }}>
                                                    <NavLink
                                                        className="link_path sign_in"
                                                        style={{ fontSize: 14 }}
                                                        to={
                                                            localStorage.getItem('language') ==
                                                                null ||
                                                            localStorage.getItem('language') ==
                                                                undefined
                                                                ? '/en'
                                                                : `/${localStorage.getItem(
                                                                      'language',
                                                                  )}/signin`
                                                        }
                                                    >
                                                        Sign into another workspace{' '}
                                                    </NavLink>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    )}
                                </ul>
                            </form>
                        </div>
                    </div>
                </nav>
            </>
        );
    }
}

export default withTranslation('common')(Navbar);
//     {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
//     <script async src="https://www.googletagmanager.com/gtag/js?id=G-GX5CSXQS2B"></script>
//     <script>
//       window.dataLayer = window.dataLayer || [];
//       function gtag(){dataLayer.push(arguments)};
//       gtag('js', new Date());

//       gtag('config', 'G-GX5CSXQS2B');
//     </script>
{
    /* 
<!-- Start of Async Drift Code -->
<script>
"use strict";

!function() {
  var t = window.driftt = window.drift = window.driftt || [];
  if (!t.init) {
    if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
    t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ], 
    t.factory = function(e) {
      return function() {
        var n = Array.prototype.slice.call(arguments);
        return n.unshift(e), t.push(n), t;
      };
    }, t.methods.forEach(function(e) {
      t[e] = t.factory(e);
    }), t.load = function(t) {
      var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
      o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
      var i = document.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(o, i);
    };
  }
}();
drift.SNIPPET_VERSION = '0.3.1';
drift.load('w5zp68iv8acy');
</script>
<!-- End of Async Drift Code --> */
}
