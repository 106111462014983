/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/jsx-key */
import React, { lazy, Suspense, useState, useEffect } from 'react';
import Navbar from '../Navbar';
import '../../Styles/Dashboard.scss';
import { Link, useLocation } from 'react-router-dom';
import '../../Styles/BlogDemo.scss';
import { BlogMCards } from './BlogMCardsDemo';
import { BulletList } from 'react-content-loader';
import { PaginationComponentDemo } from '../PaginationComponent/PaginationComponentDemo';
import _ from 'lodash';
import { Row } from 'react-bootstrap';
import { TopBlogCategory } from './TopBlogCategory';
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';

// const BlogListLoading = () => (
//     <ContentLoader viewBox="0 0 500 475" height={475} width={500}>
//         <circle cx="70.2" cy="73.2" r="41.3" />
//         <rect x="129.9" y="29.5" width="125.5" height="17" />
//         <rect x="129.9" y="64.7" width="296" height="17" />
//         <rect x="129.9" y="97.8" width="253.5" height="17" />
//         <rect x="129.9" y="132.3" width="212.5" height="17" />

//         <circle cx="70.7" cy="243.5" r="41.3" />
//         <rect x="130.4" y="199.9" width="125.5" height="17" />
//         <rect x="130.4" y="235" width="296" height="17" />
//         <rect x="130.4" y="268.2" width="253.5" height="17" />
//         <rect x="130.4" y="302.6" width="212.5" height="17" />

//         <circle cx="70.7" cy="412.7" r="41.3" />
//         <rect x="130.4" y="369" width="125.5" height="17" />
//         <rect x="130.4" y="404.2" width="296" height="17" />
//         <rect x="130.4" y="437.3" width="253.5" height="17" />
//         <rect x="130.4" y="471.8" width="212.5" height="17" />
//     </ContentLoader>
// );

const Subscribe = lazy(() => {
    return Promise.all([
        import('../Subscribe'),
        new Promise((resolve) => setTimeout(resolve, 1200)),
    ]).then(([moduleExports]) => moduleExports);
});

export const Banner = () => {
    return (
        <div style={{ margin: 10 }}>
            <div
                style={{
                    backgroundImage: `url(${'/assets/halfbanner.svg'})`,
                    backgroundSize: '50%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right',
                }}
                className="getStarted"
            >
                <p className="textGet" style={{ marginLeft: '10%' }}>
                    Get Started with{' '}
                </p>
                <p className="textGet2" style={{ marginLeft: '10%' }}>
                    Workahub
                </p>

                <Link
                    className="link_btn free"
                    style={{
                        display: 'flex',
                        alignSelf: 'left',
                        marginLeft: '10%',
                        textDecoration: 'none',
                    }}
                    to="/signup"
                >
                    <button
                        type="button"
                        className="free free_btn2 btn2"
                        //  style={{ padding: "5px", fontWeight: 'bold', height: '40px', width: '200px', textAlign: 'center' }}
                    >
                        GET STARTED
                    </button>
                </Link>
            </div>
        </div>
    );
};

const BaseLists = ({ startPage, blogRef }: { startPage: string; blogRef: string }) => {
    const [currentPage, setCurrentPage] = useState(_.toNumber(startPage) - 1);
    const [state, setState] = useState<any>('');
    const [isLoading, setLoading] = useState(false);
    const [chipCategoryState, setChipCategoryState] = useState<string[]>([]);
    const [totalBlogCount, setTotalBlogCount] = useState<number>(150);
    const handlePageClick = (selectedPage: number) => {
        setCurrentPage(selectedPage - 1);
    };

    useEffect(() => {
        if (blogRef) {
            setLoading(true);
            axios
                .get(
                    `https://workahub-blogs.cdn.prismic.io/api/v2/documents/search?ref=${blogRef}&page=1&pageSize=1`,
                )
                .then((res) => {
                    setTotalBlogCount(res.data.total_results_size);
                    setTimeout(() => {
                        fetchBlogs([]);
                        setLoading(false);
                    }, 500);
                })
                .catch((er) => {
                    setLoading(false);
                });
        }
    }, [blogRef]);
    const fetchBlogs = (categories: any) => {
        if (categories.length > 0) {
            setLoading(true);
            axios
                .get(
                    `https://workahub-blogs.cdn.prismic.io/api/v2/documents/search?ref=${blogRef}&pageSize=${totalBlogCount}&q=%5B%5Bany(my.page.categories.category%2C%5B${categories
                        .map((category: any) => `%22${category}%22`)
                        .join('%2C')}%5D)%5D%5D`,
                )
                .then((res) => {
                    if (res.data.next_page) {
                        axios.get(res.data.next_page).then((resp) => {
                            setState([...res.data.results, ...resp.data.results]);
                            setLoading(false);
                        });
                    } else {
                        setState(res.data.results);
                        setLoading(false);
                    }
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            setLoading(true);
            axios
                .get(
                    `https://workahub-blogs.cdn.prismic.io/api/v2/documents/search?ref=${blogRef}&pageSize=${totalBlogCount}`,
                )
                .then((res) => {
                    if (res.data.next_page) {
                        axios.get(res.data.next_page).then((resp) => {
                            setState([...res.data.results.slice(9, res.data.results.length), ...resp.data.results]);
                            setLoading(false);
                        });
                    } else {
                        setState(res.data.results.slice(9, res.data.results.length));
                        setLoading(false);
                    }
                })
                .catch(() => {
                    setLoading(false);
                });
        }
        setCurrentPage(0);
    };
    const updateList = (category: string) => {
        let categories: any;
        if (chipCategoryState.includes(category)) {
            categories = chipCategoryState.filter((item: string) => item !== category);
        } else {
            categories = [...chipCategoryState, category];
        }
        setChipCategoryState(categories);
        fetchBlogs(categories);
    };

    return (
        <div className="bloglists">
            <Navbar />
            <div
                style={{
                    paddingTop: '0.5rem',
                    marginLeft: '5%',
                    marginRight: '5%',
                    paddingBottom: '2rem',
                }}
            >
                {/* <BlogSearchContainer setUpdatedList={setCategoryState} /> */}
                <TopBlogCategory selectedList={chipCategoryState} updateList={updateList} />
                <div className="blogSearch">Results</div>
                <h2 className="bloglistsTrending"></h2>
                {isLoading ? (
                    <div className="blog-loading" style={{ textAlign: 'center' }}>
                        <CircularProgress size="60px" style={{ color: '#684d94' }} />
                    </div>
                ) : state && state.length >= 0 ? (
                    <>
                        <Row className="justify-content-around mt-5">
                            {state.slice(currentPage * 4, currentPage * 4 + 4).map((blog: any) => {
                                return (
                                    // eslint-disable-next-line react/jsx-key
                                    <BlogMCards
                                        id={blog.uid}
                                        key={blog.uid}
                                        className="col-6 px-md-5 mb-5"
                                        title={blog.slugs[0]}
                                        content={blog.data.meta_description[0].text}
                                        date={blog.last_publication_date.slice(0, 10)}
                                        image={blog.data.featuredimage.url}
                                        category={blog.data.categories
                                            .map((categoryElem: any) => categoryElem.category)
                                            .join(', ')}
                                    />
                                );
                            })}
                        </Row>
                        <PaginationComponentDemo
                            currentOffset={currentPage + 1}
                            articlesPerPage={4}
                            totalArticles={state.length}
                            onHandleClick={handlePageClick}
                        />
                    </>
                ) : (
                    <div className="blogSearch" style={{ textAlign: 'center' }}>
                        No data found!!
                    </div>
                )}
                <h2 className="bloglistsTrending"></h2>
            </div>
            <div className="blogBanner">
                <Banner />
            </div>
            <div>
                <Suspense fallback={<BulletList />}>
                    <Subscribe />
                </Suspense>
            </div>
        </div>
    );
};

const BlogSearchPage = () => {
    const query = new URLSearchParams(useLocation().search);
    const startPage = query.get('start') ?? '1';
    const [ref, setRef] = useState<any>('');
    useEffect(() => {
        axios.get('https://workahub-blogs.cdn.prismic.io/api/v2').then((response) => {
            setRef(response.data.refs[0].ref);
        });
    }, []);
    return <BaseLists startPage={startPage} blogRef={ref} />;
};

export default BlogSearchPage;
