import React, { useState, useEffect } from 'react';
import '../../Styles/siginstyles.scss';
import { Link } from 'react-router-dom';
import LoginImage from './loginImage';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const ManagerRegister = () => {
    const [appName, setAppName] = useState('');
    const [email, setEmail] = useState('');
    const [magicLinkToken, setMagicLinkToken] = useState('');
    const [ipAddress, setIpAdress] = useState('');
    
    
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [orgname, setOrgname] = useState('');
    const [password, setPassword] = useState('');

    const [obsToken, setObsToken] = useState('');
    

    const [resendEmail, setResendEmail] = useState(false);
    const [showEmailSent, setShowEmailSent] = useState(false);

    useEffect(() => {
        let domainName = window.location.href;
        let appNameURL = domainName.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
        const res = fetch('https://api.ipify.org?format=json').then((response) => response.json()).then((data) => {
            setIpAdress(data.ip);
        })
        setTimeout(() => {
            setAppName(appNameURL);
        }, 100);


        let params = new URL(document.location).searchParams;
        let email = params.get('email');
        setEmail(email);
        let obsTokenparams = params.get('token');
        setObsToken(obsTokenparams);

    }, []);


    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            orgname == '' ||
            firstName == '' ||
            lastName == '' ||
            userName == '' ||
            password == ''
        ) {
            alert('Please fill all the fields');
        } else {
            let domain_name;

            if (appName == 'joinworkahub.com') {
                domain_name = 'joinworkahub';
            } else if (appName == 'getsmashwork.com') {
                domain_name = 'getsmashwork';
            } else {
                domain_name = 'samsTaponShoulder';
            }

            fetch('https://api.trypadlock.com/manager/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    first_name: firstName,
                    last_name: lastName,
                    username: userName,
                    orgname: orgname,
                    password: password,
                    domain_name: domain_name,
                    ip_address: ipAddress,
                    token: obsToken,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log('Manager regoister', data);
                    if (appName == 'joinworkahub.com') {
                        window.location.href = `https://${orgname}.joinworkahub.com/redirect?token=${data.access_token}`;
                    } else if (appName == 'getsmashwork.com') {
                        window.location.href = `https://${orgname}.getsmashwork.com/redirect?token=${data.access_token}`;
                    } else {
                        window.location.href = `https://${orgname}.samstapontheshoulder.com/redirect?token=${data.access_token}`;
                    }
                })
                .catch((err) => {
                    console.log('Error in creating Magic Link', err);
                });
        }
    };

    return (
        <div style={{ backgroundColor: '#F3F4F8', height: '100vh' }}>
            <div className="row m-0">
                <div className="col-md-6 no-padding image_hide">
                    <LoginImage appname={appName} />
                </div>
                <div className="col-md-6">
                    <div className="row m-0">
                        <div className="col-md-8 offset-md-2">
                            {!showEmailSent ? (
                                <div className="make_desktop">
                                    {appName == 'joinworkahub.com' ? (
                                        <img
                                            className="image_hub work_img"
                                            style={{ height: 26 }}
                                            src={'/assets/workahub.svg'}
                                        />
                                    ) : appName == 'getsmashwork.com' ||
                                      appName == 'localhost:3000' ? (
                                        <img
                                            className="image_hub work_img"
                                            style={{ height: 31 }}
                                            src={'/assets/SmashworkLogo.svg'}
                                        />
                                    ) : (
                                        <img
                                            className=""
                                            style={{ height: 68 }}
                                            src={'/assets/newSamtap_logo.png'}
                                        />
                                    )}

                                    <h1
                                        className="email_text text-align"
                                        style={{ margin: '8px 0 55px 0', textAlign: 'center' }}
                                    >
                                        Register
                                    </h1>
                                    {/* <form className="form_view"> */}
                                    <div
                                        className="obsfields"
                                        style={{ display: 'flex', marginBottom: '20px' }}
                                    >
                                        <div
                                            className="obsinput"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                            }}
                                        >
                                            <p className="email_text">First Name</p>
                                            <div
                                                className=""
                                                style={{ display: 'flex', justifyContent: 'start' }}
                                            >
                                                <input
                                                    type="text"
                                                    id="firstname"
                                                    name="firstname"
                                                    style={{
                                                        width: '210px',
                                                        height: '44px',
                                                        border: '1px solid #D1D1D1',
                                                        borderRadius: '5px',
                                                        padding: '0px 10px',
                                                    }}
                                                    // className="form-control"
                                                    placeholder="Enter First Name"
                                                    onChange={(e) => {
                                                        setFirstName(e.target.value);
                                                    }}
                                                    noValidate
                                                    data-hj-allow
                                                />
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <p className="email_text">Last Name</p>
                                            <div
                                                className=""
                                                style={{ display: 'flex', justifyContent: 'start' }}
                                            >
                                                <input
                                                    type="text"
                                                    id="lastname"
                                                    name="lastname"
                                                    style={{
                                                        width: '210px',
                                                        height: '44px',
                                                        border: '1px solid #D1D1D1',
                                                        borderRadius: '5px',
                                                        padding: '0px 10px',
                                                    }}
                                                    // className="form-control"
                                                    placeholder="Enter Last Name"
                                                    onChange={(e) => {
                                                        setLastName(e.target.value);
                                                    }}
                                                    noValidate
                                                    data-hj-allow
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="obsfields" style={{ display: 'flex' }}>
                                        <div
                                            className="obsinput"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                            }}
                                        >
                                            <p className="email_text">Username</p>
                                            <div
                                                className=""
                                                style={{ display: 'flex', justifyContent: 'start' }}
                                            >
                                                <input
                                                    type="text"
                                                    id="username"
                                                    name="username"
                                                    style={{
                                                        width: '210px',
                                                        height: '44px',
                                                        border: '1px solid #D1D1D1',
                                                        borderRadius: '5px',
                                                        padding: '0px 10px',
                                                    }}
                                                    // className="form-control"
                                                    placeholder="Enter Username"
                                                    onChange={(e) => {
                                                        setUserName(e.target.value);
                                                    }}
                                                    noValidate
                                                    data-hj-allow
                                                />
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <p className="email_text">Oragnization</p>
                                            <div
                                                className=""
                                                style={{ display: 'flex', justifyContent: 'start' }}
                                            >
                                                <input
                                                    type="text"
                                                    id="organization"
                                                    name="organization"
                                                    style={{
                                                        width: '210px',
                                                        height: '44px',
                                                        border: '1px solid #D1D1D1',
                                                        borderRadius: '5px',
                                                        padding: '0px 10px',
                                                    }}
                                                    // className="form-control"
                                                    placeholder="Enter Organization"
                                                    onChange={(e) => {
                                                        setOrgname(e.target.value);
                                                    }}
                                                    noValidate
                                                    data-hj-allow
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ marginTop: '15px', width: '100%' }}>
                                        <p className="email_text">Password</p>
                                        <div className="inner-addon left-addon input_control">
                                            <input
                                                type="password"
                                                id="password"
                                                name="password"
                                                style={{
                                                    width: '100%',
                                                    height: '54px',
                                                    border: '1px solid #D1D1D1',
                                                    borderRadius: '5px',
                                                    padding: '0px 10px',
                                                }}
                                                placeholder="Password "
                                                onChange={(e) => {
                                                    setPassword(e.target.value);
                                                }}
                                                noValidate
                                                data-hj-allow
                                            />
                                        </div>
                                    </div>

                                    {/* <button name = 'submit' onClick={this.handleSubmit} type="submit" className="btn btn_submit">Confirm</button> */}

                                    <button
                                        className="btn  btn_submit btn-size"
                                        onClick={(e) => {
                                            handleSubmit(e);
                                        }}
                                    >
                                        Continue
                                    </button>

                                    {/* </form> */}
                                </div>
                            ) : (
                                <div
                                    style={{
                                        height: '75vh',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div style={{}}>
                                        <div>
                                            <img
                                                src={
                                                    resendEmail
                                                        ? '/assets/Send.png'
                                                        : '/assets/email.png'
                                                }
                                                alt="email"
                                                style={{ height: '120px', width: 'auto' }}
                                            />
                                        </div>
                                        <div>
                                            <h2 style={{ fontSize: '30px', fontWeight: 'bold' }}>
                                                {resendEmail
                                                    ? 'Resent successful!'
                                                    : 'Check Your Email!'}
                                            </h2>
                                            <p
                                                style={{
                                                    marginTop: '10px',
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    textAlign: 'center',
                                                    color: '#8692A6',
                                                }}
                                            >
                                                {resendEmail
                                                    ? `Link has been successfully re-shared on the ${email}. Please check your spam folder`
                                                    : `Please click the link shared to ${email} to login`}
                                            </p>
                                        </div>
                                        <div style={{ marginTop: '30px' }}>
                                            <button
                                                style={{
                                                    border: '1px solid #D2D2D2',
                                                    padding: '5px 35px',
                                                    borderRadius: '3px',
                                                    margin: '10px 0px',
                                                }}
                                                onClick={(e) => {
                                                    handleSubmit(e);
                                                    setResendEmail(true);
                                                }}
                                            >
                                                Resend It
                                            </button>
                                        </div>
                                        {resendEmail ? (
                                            <div>
                                                <p style={{ color: '#8692A6' }}>
                                                    Not able to login? Please email us at{' '}
                                                    <span style={{ color: 'blue' }}>
                                                        support@workahub.com
                                                    </span>
                                                </p>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        textAlign: 'center',
                                                        margin: '20px 0px',
                                                    }}
                                                >
                                                    OR
                                                </div>
                                                <div>
                                                    <button
                                                        style={{
                                                            border: 'none',
                                                            padding: '10px 45px',
                                                            borderRadius: '3px',
                                                            backgroundColor: '#684D94',
                                                            color: '#fff',
                                                        }}
                                                        onClick={() => {
                                                            history.back();
                                                        }}
                                                    >
                                                        Login via Password
                                                    </button>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManagerRegister;
