/* eslint-disable react/prop-types */
import React from 'react';

export default class Remove extends React.Component {
    constructor(props) {
        super(props);
        this.removeFunct = this.removeFunct.bind(this);
    }

    removeFunct = () => {
        const query = new URLSearchParams(this.props.location.search);

        let email_id = query.get('token');
        let infor = localStorage.getItem('names');
        if (infor) {
            let lists = JSON.parse(infor);

            if (lists[atob(email_id)]) {
                delete lists[atob(email_id)];
                localStorage.setItem('names', JSON.stringify(lists));
            }
        }
        // let emails = localStorage.removeItem(atob(email_id));
        if (email_id) {
            window.location.href = '/';
        }
    };;

    render() {
        return <div>{console.log(this.removeFunct())}</div>;
    }
}
