import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import USAPng from '/assets/united-states.png'
import MexicoPng from '../../public/assets/mexico.png'
import PortugalPng from '../../public/assets/portugal.png'
import SAng from '../../public/assets/saudi-arabia.png'

import Select from 'react-select';


const LanguageSelection = () => {

    const countryOptions = [
        {key: 'en', value: 'en', flag: 'in', label: "English", image: USAPng},
        {key: 'es', value: 'es', flag: 'es', label: 'Spanish', image: MexicoPng},
        {key: 'pt', value: 'pt', flag: 'pt', label: 'Portuguese', image: PortugalPng},
        {key: 'sa', value: 'sa', flag: 'sa', label: 'Arabic', image: SAng},
    ];

    useEffect(() => {
        const lang = localStorage.getItem('language');
        const urlLang = window.location.href.split('/')[3];
        const defaultLang = urlLang !=='es'|| urlLang !=='en'|| urlLang !=='sa'|| urlLang !=='pt'? lang : urlLang;

        if (defaultLang == 'en') {
            localStorage.setItem('languageFlag', JSON.stringify(countryOptions[0]));
        } else if (defaultLang == 'es') {
            localStorage.setItem('languageFlag', JSON.stringify(countryOptions[1]));
        } else if (defaultLang == 'pt') {
            localStorage.setItem('languageFlag', JSON.stringify(countryOptions[2]));
        } else if (defaultLang == 'sa') {
            localStorage.setItem('languageFlag', JSON.stringify(countryOptions[3]));
        }

        const currentUrlData = window.location.href.split('/');
        let newUrl = currentUrlData[0];

        for (let i = 1; i < currentUrlData.length; i++) {
            if (i == 3) {
                newUrl += '/' + defaultLang;
            } else {
                newUrl += '/' + currentUrlData[i];
            }
        }
        history.replaceState({}, null, newUrl);
        i18n.changeLanguage(defaultLang);
    }, [])


    const {t, i18n} = useTranslation('common');

    const handleLanguageChange = (e) => {
        const currentUrlData = window.location.href.split('/');
        var newUrl = currentUrlData[0];
        for( let i = 1; i < currentUrlData.length; i++ )
        {
            if (i == 3)
            {
                newUrl += "/" + e.value
            }
            else
            {
                newUrl += "/" + currentUrlData[i];
            }
        }
        history.replaceState({}, null, newUrl);
        i18n.changeLanguage(e.value);
        localStorage.setItem('language', e.value);
        localStorage.setItem('languageFlag', JSON.stringify(e));
    }

    const customStyles = {
        control: base => ({
          ...base,
          height: 40,
          width: 160,
          minHeight: 40,
          minWidth: 160
        })
    };


    return (
        <div style={{width: '160px'}}>
            <Select 
                value={JSON.parse(localStorage.getItem('languageFlag'))}
                options={countryOptions}
                defaultValue={countryOptions[0]}
                onChange={(e) => {handleLanguageChange(e)}}
                isSearchable={false}
                menuPlacement="top"
                formatOptionLabel={(country) => (
                    <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
                        <img src={country.image} alt={country.label} height={20} width={20} style={{marginRight: '4px'}}/>
                        <span>{country.label}</span>
                    </div>
                )}
                // defaultValue={{ label: "English", value: "en" }}
                styles={customStyles}
            />
             {/* <select onChange={(e) => {handleLanguageChange(e)}} style={{height: '40px', width: '120px', borderRadius: '5px', marginRight: '10px', transform: "translateY(5px)"}} value={localStorage.getItem('language')}>
                {countryOptions.map((country) => (
                    <option key={country.key} value={country.value}>
                        {country.label}
                    </option>
                ))}
            </select> */}
        </div>
    );
}

export default LanguageSelection;