/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import '../../Styles/Blog.scss';
import { Col } from 'react-bootstrap';
import _ from 'lodash';

interface BlogMCardsProps {
    id: string;
    title: string;
    className?: string;
    content: string;
    date?: string;
    image: string;
    category: string;
}

export const BlogMCards = ({
    id,
    title,
    className,
    content,
    date,
    image,
    category,
}: BlogMCardsProps) => {
    const nextBlog = (id: string, title: string) => {
        window.location.href = `/en/blog/${id.slice(4, id.length)}/${title
            .split('-')
            .filter((e) => e !== '')
            .join('-')}`;
    };
    return (
        <Col
            className={className}
            onClick={() =>
                nextBlog(
                    id,
                    title
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, ''),
                )
            }
        >
            <div>
                {image == undefined ? (
                    <></>
                ) : (
                    <img className="maincardImg" src={image} alt={title} />
                )}
            </div>
            <div className="maincardContentContainer">
                <div>
                    <h4 className="blogBusinnessTitle">{category}</h4>
                    <p className="maincardContent">{content}</p>
                </div>
            </div>
        </Col>
    );
};
