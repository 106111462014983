/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import './App.scss';

const ErrorFallback = () => {
    const [appname, setAppname] = useState('');
    useEffect(() => {
        let domainName = window.location.href;
        let appNameURL = domainName.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
        setAppname(appNameURL);
    }, []);

    return (
        <div
            style={{
                backgroundImage: `url(${'/assets/404.svg'})`,
                backgroundSize: '100% 142vh',
                // backgroundRepeat: "no-repeat",
                height: '100vh',
                objectFit: 'contain',
            }}
        >
            <div className="errorContainer">
                <div className="errorTitle">404</div>
                <div className="errorDesc">Page Not Found</div>
                <div className="errorContent">
                    Uh oh, we can’t seem to find the page you’re looking for. Try going back to the
                    Home page{' '}
                    {appname == 'getsmashwork.com' &&
                        'or read the latest from our blog, Success Factors.'}
                </div>
                <div className="d-flex justify-content-center flex-column gap-3 mt-3 flex-md-row">
                    <button
                        className="errorHome"
                        onClick={() => {
                            window.location.href = '/en/home';
                        }}
                    >
                        Home
                    </button>
                    {appname == 'getsmashwork.com' && (
                        <button
                            className="errorSuccess"
                            onClick={() => {
                                window.location.href = '/en/blog';
                            }}
                        >
                            Blogs
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ErrorFallback;
