import { gql } from '@apollo/client';

export const GetPaginatedBlogs = gql`
query Blogs ($limit: Int, $start: Int){ blogs(pagination: { start:$start,limit:$limit}){ data{ id attributes { Author blogtitle blogcontent feature_image_alt_text meta_description publishedAt createdAt updatedAt categories{ data{ id attributes{ categoryName __typename } } } featuredImage{ data{ id attributes{ name width height hash ext size url __typename } } } __typename } } } }`;

export const GetBlogIds = gql`
    
    query Blogs($start: Int) {
        blogs(pagination:{start:$start}) {
            data{
                id
            	attributes{
                	categories {
                        data{
                            id
                        }
                	}  
                }
                
            }
        }
    }
`;

export const GetBlogDetailsById = gql`
    query Blogs($id: ID!) {
        blog(id: $id) {
            data{
              id
              attributes{
                    createdAt
                    blogtitle
                    Author
                    blogcontent
                    meta_description
                    feature_image_alt_text
                    publishedAt
                		categories {
                      data{
                        id
                        attributes{
                          categoryName 
                        }
                      }  
            		}
                	featuredImage {
                    data{
                      id
                      attributes{
                      name
                      caption
                      width
                      height
                      hash
                      ext
                      size
                      url 
                      }
                    }  
                  }
              }
            }
        
        }
    }
`;

export const GetCategories = gql`
    query Categories($start: Int!) {
        categories(pagination:{start: $start}) {
            data{
               id
               attributes{
                categoryName
               } 
            }            
        }
    }
`;




// query GET_PAGINATED_BLOGS($limit: Int, $start: Int) {
//     blogs(limit: $limit, start: $start) {
//         id
//         created_at
//         blogtitle
//         blogcontent
//         meta_description
//         feature_image_alt_text
//         published_at
//         Author
//         categories {
//             id
//             categoryName
//         }
//         featuredImage {
//             id
//             name
//             caption
//             width
//             height
//             size
//             url
//         }
//     }
// }