import React, { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorBoudary';
import fetch from 'cross-fetch';
import './App.scss';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import BlogSearchPage from './Components/Blog/BlogSearchPage';
import { DashBoard } from './Components/DashBoard';
import { TermsofService } from './Components/TermofServices';
import { PrivacyPolicy } from './Components/PrivacyPolicy';
import BlogSingle from './Components/Blog/BlogSingle';
import { LinearProgress } from '@material-ui/core';
import { HttpLink, ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import UnSubscribe from './Components/UnSubscribe';
import Organization_magicLink from './Components/Logins/Organization_magicLink';
import ObserverRegister from './Components/Logins/Observer_Register';
import  ManagerRegister  from './Components/Logins/Manager_Register';
import Support from './Components/Support';
import { Helmet } from 'react-helmet-async';
import Remove from './remove';
import { Toaster } from 'react-hot-toast';
import { GoogleOAuthProvider } from '@react-oauth/google';
const client = new ApolloClient({
    link: new HttpLink({ uri: 'https://strapi.s7works.io/graphql', fetch }),
    cache: new InMemoryCache(),
});

const ResetPassword = lazy(() => {
    return Promise.all([
        import('../src/Components/ForgotPassword/Resetpassword'),
        new Promise((resolve) => setTimeout(resolve, 1000)),
    ]).then(([moduleExports]) => moduleExports);
});

const EmailVerification = lazy(() => {
    return Promise.all([
        import('../src/Components/Logins/emailverfication'),
        new Promise((resolve) => setTimeout(resolve, 300)),
    ]).then(([moduleExports]) => moduleExports);
});

const LoginOrganization = lazy(() => {
    return Promise.all([
        import('../src/Components/Logins/Organization'),
        new Promise((resolve) => setTimeout(resolve, 300)),
    ]).then(([moduleExports]) => moduleExports);
});

const SetPassword = lazy(() => {
    return Promise.all([
        import('../src/Components/Logins/Setpassword'),
        new Promise((resolve) => setTimeout(resolve, 1000)),
    ]).then(([moduleExports]) => moduleExports);
});

const EmailVerify = lazy(() => {
    return Promise.all([
        import('../src/Components/Logins/Emailverify'),
        new Promise((resolve) => setTimeout(resolve, 1000)),
    ]).then(([moduleExports]) => moduleExports);
});

const User_Signup = lazy(() => {
    return Promise.all([
        import('../src/Components/Logins/Usersignup'),
        new Promise((resolve) => setTimeout(resolve, 300)),
    ]).then(([moduleExports]) => moduleExports);
});

const Registered = lazy(() => {
    return Promise.all([
        import('../src/Components/Logins/registered'),
        new Promise((resolve) => setTimeout(resolve, 1000)),
    ]).then(([moduleExports]) => moduleExports);
});

const Signup = lazy(() => {
    return Promise.all([
        import('./Components/Logins/signup'),
        new Promise((resolve) => setTimeout(resolve, 1000)),
    ]).then(([moduleExports]) => moduleExports);
});

const ForgotPassword = lazy(() => {
    return Promise.all([
        import('./Components/ForgotPassword/forgotpassword'),
        new Promise((resolve) => setTimeout(resolve, 1000)),
    ]).then(([moduleExports]) => moduleExports);
});

// const Remove = lazy(() => {
//     return Promise.all([
//         import('./remove'),
//         new Promise((resolve) => setTimeout(resolve, 1000)),
//     ]).then(([moduleExports]) => moduleExports);
// });

const Phonenumber = lazy(() => {
    return Promise.all([
        import('./Components/Logins/Phonenumber'),
        new Promise((resolve) => setTimeout(resolve, 1000)),
    ]).then(([moduleExports]) => moduleExports);
});

const Register = lazy(() => {
    return Promise.all([
        import('./Components/Registration'),
        new Promise((resolve) => setTimeout(resolve, 1000)),
    ]).then(([moduleExports]) => moduleExports);
});

const Signin = lazy(() => {
    return Promise.all([
        import('./Components/Signin/signin'),
        new Promise((resolve) => setTimeout(resolve, 300)),
    ]).then(([moduleExports]) => moduleExports);
});

const Password = lazy(() => {
    return Promise.all([
        import('./Components/Signin/signinPassword'),
        new Promise((resolve) => setTimeout(resolve, 300)),
    ]).then(([moduleExports]) => moduleExports);
});

const Features = lazy(() => {
    return Promise.all([
        import('../src/Components/features'),
        new Promise((resolve) => setTimeout(resolve, 500)),
    ]).then(([moduleExports]) => moduleExports);
});

const Pricing = lazy(() => {
    return Promise.all([
        import('../src/Components/pricing'),
        new Promise((resolve) => setTimeout(resolve, 1000)),
    ]).then(([moduleExports]) => moduleExports);
});

const Download = lazy(() => {
    return Promise.all([
        import('../src/Components/Download'),
        new Promise((resolve) => setTimeout(resolve, 500)),
    ]).then(([moduleExports]) => moduleExports);
});

const About = lazy(() => {
    return Promise.all([
        import('../src/Components/about'),
        new Promise((resolve) => setTimeout(resolve, 1000)),
    ]).then(([moduleExports]) => moduleExports);
});

const BlogDemo = lazy(() => {
    return Promise.all([
        import('../src/Components/Blog/BlogDemo'),
        new Promise((resolve) => setTimeout(resolve, 1000)),
    ]).then(([moduleExports]) => moduleExports);
});

const Blogsingle = lazy(() => {
    return Promise.all([
        import('../src/Components/Blog/BlogSingle'),
        new Promise((resolve) => setTimeout(resolve, 1000)),
    ]).then(([moduleExports]) => moduleExports);
});

const Page404 = lazy(() => {
    return Promise.all([
        import('./ErrorBoudary'),
        new Promise((resolve) => setTimeout(resolve, 1000)),
    ]).then(([moduleExports]) => moduleExports);
});

function App() {
    const [isUserAuthenticated] = React.useState(true);
    const url = window.location.href;
    const splittedUrl = url.split('/');
    if (splittedUrl.length <= 4) {
        window.location.href =
            (!localStorage.getItem('language') ? 'en' : localStorage.getItem('language')) +
            (splittedUrl.length == 4 ? '/home' : '');
    } else if (
        splittedUrl.length == 5 &&
        splittedUrl[4] == '' &&
        (splittedUrl[3] == 'en' ||
            splittedUrl[3] == 'es' ||
            splittedUrl[3] == 'pt' ||
            splittedUrl[3] == 'sa')
    ) {
        window.location.href = 'home';
    }
    // const url = 'https://www.samstapontheshoulder.com/en/home';
    // let domain = (new URL(url));
    // domain = domain.hostname;
    // console.log('DOMIAIN', domain);

    // const url = 'https://www.joinworkahub.com/en/home';

    //   function getCurrentLang(){
    //     return window.location.href.split('/')[3];
    //   }
    if (!localStorage.getItem('names')) {
        localStorage.setItem('names', '{}');
    }
    let appNameURL = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
    // basename={localStorage.getItem('language') == null || localStorage.getItem('language') == undefined ? '/en' : '/' + localStorage.getItem('language')}

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <GoogleOAuthProvider clientId="115840353103-nrk6b4rs4m72od7shkgq9lreto10k88r.apps.googleusercontent.com">
                <ApolloProvider client={client}>
                    <Helmet>
                        <title>Smart Employee Time Tracking Software with Screenshots</title>
                        <meta
                            name="title"
                            content="Smart Employee Time Tracking Software with Screenshots"
                            data-react-helmet="true"
                        />
                        <meta
                            name="description"
                            content="Workahub is an employee time tracking software that helps your team get more done each day. Try for free - no credit card required"
                            data-react-helmet="true"
                        />

                        <meta
                            property="og:image:type"
                            content="image/png"
                            data-react-helmet="true"
                        />
                        <meta
                            property="og:image"
                            content="../public/timescreen.png"
                            data-react-helmet="true"
                        />
                        <link rel="icon" href="../public/favicon.svg" data-react-helmet="true" />
                    </Helmet>
                    <div className="App">
                        <BrowserRouter>
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    render={() => {
                                        return isUserAuthenticated ? (
                                            <Redirect to={`en/home`} />
                                        ) : (
                                            <Redirect to={'en/features'} />
                                        );
                                    }}
                                />

                                <Suspense fallback={<LinearProgress />}>
                                    <Switch>
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/home`}
                                            component={DashBoard}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/features`}
                                            component={Features}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/pricing`}
                                            component={Pricing}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/Download`}
                                            component={Download}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/about`}
                                            component={About}
                                        />
                                        {appNameURL != 'joinworkahub.com' && (
                                            <Route
                                                exact
                                                path={`${
                                                    localStorage.getItem('language') == null ||
                                                    localStorage.getItem('language') == undefined
                                                        ? '/en'
                                                        : `/${localStorage.getItem('language')}`
                                                }/blog`}
                                                component={BlogDemo}
                                            />
                                        )}
                                        {appNameURL != 'joinworkahub.com' && (
                                            <Route
                                                exact
                                                path={`${
                                                    localStorage.getItem('language') == null ||
                                                    localStorage.getItem('language') == undefined
                                                        ? '/en'
                                                        : `/${localStorage.getItem('language')}`
                                                }/bloglists`}
                                                component={BlogSearchPage}
                                            />
                                        )}
                                        {appNameURL != 'joinworkahub.com' && (
                                            <Route
                                                exact
                                                path={`${
                                                    localStorage.getItem('language') == null ||
                                                    localStorage.getItem('language') == undefined
                                                        ? '/en'
                                                        : `/${localStorage.getItem('language')}`
                                                }/blogsingle`}
                                                component={Blogsingle}
                                            />
                                        )}
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/termsofservice`}
                                            component={TermsofService}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/privacy-policy`}
                                            component={PrivacyPolicy}
                                        />
                                        {appNameURL != 'joinworkahub.com' && (
                                            <Route
                                                exact
                                                path={`${
                                                    localStorage.getItem('language') == null ||
                                                    localStorage.getItem('language') == undefined
                                                        ? '/en'
                                                        : `/${localStorage.getItem('language')}`
                                                }/blog/:id/:title`}
                                                component={BlogSingle}
                                            />
                                        )}

                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/emailverify`}
                                            component={EmailVerify}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/signup`}
                                            component={Signup}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/signin`}
                                            component={Signin}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/employeeRegister`}
                                            component={Register}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/siginpassword`}
                                            component={Password}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/user_signup`}
                                            component={User_Signup}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/phonenumber`}
                                            component={Phonenumber}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/organization`}
                                            component={LoginOrganization}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/setpassword`}
                                            component={SetPassword}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/forgotpassword`}
                                            component={ForgotPassword}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/resetpassword`}
                                            component={ResetPassword}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/emailverfication`}
                                            component={EmailVerification}
                                        />
                                        <Route exact path={`/remove`} component={Remove} />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/registered`}
                                            component={Registered}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/unSubscribe`}
                                            component={UnSubscribe}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/organization-magic-link`}
                                            component={Organization_magicLink}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/observer-register`}
                                            component={ObserverRegister}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/manager-register`}
                                            component={ManagerRegister}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/support`}
                                            component={Support}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                localStorage.getItem('language') == null ||
                                                localStorage.getItem('language') == undefined
                                                    ? '/en'
                                                    : `/${localStorage.getItem('language')}`
                                            }/app_url`}
                                            component={() => {
                                                if (appNameURL == 'joinworkahub.com') {
                                                    window.location.href =
                                                        'https://app.joinworkahub.com';

                                                    return null;
                                                } else if (appNameURL == 'getsmashwork.com') {
                                                    window.location.href =
                                                        'https://app.getsmashwork.com';
                                                    return null;
                                                } else {
                                                    window.location.href =
                                                        'https://www.samstapontheshoulder.com';

                                                    return null;
                                                }
                                            }}
                                        />
                                        {/* <Route
                                        exact
                                        path={
                                            (localStorage.getItem('language') == null ||
                                            localStorage.getItem('language') == undefined
                                                ? '/en'
                                                : '/' +
                                                  localStorage.getItem('language')) +
                                                  '/blog.samstapontheshoulder'
                                        }
                                        component={() => {
                                            if (appNameURL == 'samstapontheshoulder.com' || appNameURL=='localhost:3000') {
                                                window.location.replace('https://www.blog.samstapontheshoulder.com');
                                                return null;
                                            } else {
                                                return null;
                                            }
                                        }}
                                    /> */}
                                        <Route path="*" component={Page404} />
                                    </Switch>
                                </Suspense>
                                <Route path="*" component={Page404} />
                            </Switch>
                        </BrowserRouter>
                        <Toaster reverseOrder={false} position="top-right" />
                    </div>
                </ApolloProvider>
            </GoogleOAuthProvider>
        </ErrorBoundary>
    );
}

export default App;
