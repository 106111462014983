/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { lazy, Suspense, useState } from 'react';
import '../Styles/Dashboard.scss';
import Navbar from './Navbar';




const ChooseBetterWay = lazy(() => {
    return Promise.all([
        import('./ChooseBetterWay'),
        new Promise((resolve) => setTimeout(resolve, 1000)),
    ]).then(([moduleExports]) => moduleExports);
});

const Subscribe = lazy(() => {
    return Promise.all([
        import('./Subscribe'),
        new Promise((resolve) => setTimeout(resolve, 1200)),
    ]).then(([moduleExports]) => moduleExports);
});

export const TermsofService = () => {
    const url = window.location.href;
    // const url = 'https://www.samstapontheshoulder.com/en/home';
    // let domain = (new URL(url));
    // domain = domain.hostname;
    // console.log('DOMIAIN', domain);

    // const url = 'https://www.joinworkahub.com/en/home';

    let wow = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
    // console.log('WOW', wow);

    return (
        <>
            <Navbar appname={wow} />
            <div className="dashboard_div">
                <>
                    <div
                        className=""
                        style={{ marginLeft: '10%', marginRight: '10%', marginBottom: '6%' }}
                    >
                        <div className="termsofserviceheading">Terms of Service</div>
                        <hr style={{ color: '#684D94', marginTop: '0px' }} />
                        <div
                            className="termscontent"
                            style={{ textAlign: 'left', marginLeft: '32px', marginRight: '32px' }}
                        >
                            <p>
                                These Service Terms and Conditions ("Agreement") comprise an
                                agreement between S7 Works LLC located at 1497 Conley Way, Conley Ga
                                30288. S7 Works LLC wishes to give, and you wish to reserve the
                                privilege to get to according to the particulars of this Agreement,
                                a membership to the web and mobile applications and services of S7
                                Works ("Services").{' '}
                            </p>
                            <p>
                                This Agreement incorporates and consolidates the form used to buy
                                the Services and any subsequent paper or electronic forms (“Order
                                Form”) in addition to any other agreements mutually agreed to in
                                writing. The effective date ("Effective Date") of this Agreement is
                                the date that is prior of (a) the successful date of the principal
                                Order Form referring to this Agreement, and (b) Customer's
                                underlying admittance to the Services through any online enrollment,
                                provisioning or request measure.
                            </p>
                            <p>
                                By getting to or utilizing the Services, including any Free Services
                                as depicted underneath, you consent to be limited by this Agreement.
                                In case you are going into this Agreement in the interest of an
                                organization, association, or other element, you address that you
                                have such power to tie such element and are consenting to this
                                Agreement for such substance. In the event that you don't have such
                                power to go into this Agreement or don't concur with this Agreement,
                                you may not utilize the Services.{' '}
                            </p>
                        </div>
                        <div
                            className="termscontent"
                            style={{ textAlign: 'left', marginLeft: '32px', marginRight: '32px' }}
                        >
                            <div className="label"> 1. Obligations </div>
                            <p>
                                1.1 Subject to and adapted on Customer's installment of Fees and
                                full consistence with this Agreement, S7 Works awards Customer a
                                non-elite, non-sublicensable, non-adaptable permit during the
                                timeframe in which every membership stays essentially ("Term") to
                                access and utilize the Services, as per the documentation S7 Works
                                might refresh occasionally regarding redesigns ("Documentation"). S7
                                Works Services are accommodated Customer's business utilize just,
                                not intended for private use.{' '}
                            </p>
                            <p>
                                1.2 Use of the Services might expect Customer to introduce S7 Works
                                portable programming ("Software") on cell phones or work areas,
                                which use will be dependent upon this Agreement. S7 Works awards
                                Customer a non-restrictive, non-sublicensable, non-adaptable permit
                                to introduce and utilize the Software just in object code structure
                                on viable gadgets recognized in the Documentation, exclusively for
                                authorized utilization of the Services during the Term and as per
                                the pertinent Order Form.{' '}
                            </p>
                            <p>
                                1.3 During the Term, S7 Works will offer essential help for the
                                Services to Customer as portrayed in the assistance level
                                understanding ("SLA"). S7 Works will offer the Types of assistance
                                as per appropriate laws and unofficial laws. The Services, the SLA,
                                and the Documentation are dependent upon alteration occasionally at
                                S7 Works's sole prudence, given the adjustments don't really reduce
                                the usefulness of the Services given by S7 Works and the Services
                                keep on performing as per the depiction of the Services indicated in
                                Section 1.4 in every material angle. Client will reserve the option
                                to end the Agreement according to Section 9.2 with no punishment on
                                the off chance that (I) a material change to the Services, the SLA
                                or the documentation is made which tangibly lessens the usefulness
                                of the Services or physically reduces the SLA; (ii) S7 Works has not
                                acquired Customer's assent for such alterations; and (iii) S7 Works
                                doesn't give a cure in the fix time frame expressed in Section 9.2.{' '}
                            </p>
                            <p>
                                1.4 S7 Works will make the Services accessible and the Services will
                                perform generously as per the portrayal at
                                https://joinworkahub.com/en/pricing. Regardless the previous, S7
                                Works maintains all authority to suspend Customer's admittance to
                                the Services quickly (I) if Customer breaks Section 3 or Section 6
                                of this Agreement, or penetrates some other arrangement of this
                                Agreement and neglects to address that break inside the appropriate
                                fix time frame; or (ii) as it considers sensibly significant to
                                react to any real or expected security or accessibility worry that
                                might influence Customer. Furthermore, S7 Works may, without
                                responsibility, suspend the Services to a few or the entirety of the
                                Users (a) as important to forestall an endeavored security break or
                                digital assault, (b) to ensure S7 Works's or its clients frameworks;
                                or (c) whenever needed by an administrative substance or law
                                implementation office. Client will get notice of such suspension, to
                                the degree and in the way that S7 Works gives a notice to its other
                                influenced clients.{' '}
                            </p>
                            <p>
                                1.5 S7 Works might utilize information identified with the
                                utilization of the Services by Customer that (I) doesn't explicitly
                                recognize Customer, Users or outsiders, and (ii) is joined with the
                                information of different clients, clients or extra information
                                sources ("Aggregated Data") for the accompanying purposes: (a)
                                keeping up with, improving, as well as dissecting the Services,
                                including investigation and detailing, (b) conforming to legitimate
                                or authoritative necessities, (c) examining and moderating security
                                dangers like weaknesses or systems administration issues, and (d)
                                creating, disseminating and distributing measures and reports of the
                                Services. Amassed Data isn't Confidential Information and won't be
                                dependent upon any classification limitations or commitments under
                                Section 5.{' '}
                            </p>
                        </div>
                        <div
                            className="termscontent"
                            style={{ textAlign: 'left', marginLeft: '32px', marginRight: '32px' }}
                        >
                            <div className="label"> 2. Client Responsibilities </div>
                            <p>
                                2.1 Customer may just utilize the Services as per the Documentation,
                                pertinent laws and unofficial laws and as unequivocally set out in
                                this Agreement. Client will help out S7 Works regarding the
                                exhibition of this Agreement as might be essential, which might
                                incorporate making accessible such staff and data as might be
                                sensibly needed to offer the Types of assistance or backing. Client
                                is exclusively answerable for deciding if the Services are adequate
                                for its motivations, including, however not restricted to,
                                regardless of whether the Services fulfill Customer's lawful and
                                additionally administrative necessities.{' '}
                            </p>
                            <p>
                                2.2 Customer will be exclusively liable for the precision,
                                uprightness and lawfulness of any substance or information,
                                including individual information as this term is characterized in
                                the pertinent information insurance laws, presented by or for the
                                benefit of Customer to the Services and the yield of the Services
                                that joins such substance or information or is generally explicit to
                                ("Customer Data"). Client will not give any encroaching, hostile,
                                fake, or unlawful Customer Data regarding the Services, and Customer
                                addresses and warrants that any substance it gives won't abuse the
                                licensed innovation or information assurance rights, among different
                                rights, of any User or outsider. S7 Works saves the right, in its
                                sole caution, to erase or debilitate any Customer Data presented by
                                Customer that might be encroaching, hostile, deceitful, or illicit.
                                As between the Parties, Customer only possesses OK, title and
                                interest in and to all Customer Data and S7 Works asserts no right,
                                title or interest in the Customer Data. Subject to the particulars
                                of this Agreement, Customer thus allows S7 Works a non-selective,
                                around the world, eminence free right to utilize, duplicate, store,
                                send, change, make subsidiary works of, and perform and show in
                                Customer's record the Customer Data exclusively to the degree
                                important to offer the Types of assistance to Customer. Further, S7
                                Works may intermittently get to Customer's record and Customer Data
                                to improve, support, and work the Services (e.g., for quality
                                confirmation, benchmarking, specialized help, or charging purposes).{' '}
                            </p>
                            <p>
                                2.3 regarding the Services, Customer (or its Users) may decide to
                                utilize or get outsider items or administrations that are not
                                authorized to Customer straight by S7 Works ("Third-Party
                                Services"). The Customer's utilization of such Third Party Services
                                will be administered exclusively by the agreements material to such
                                Third-Party Services, as consented to among Customer and the
                                outsider. S7 Works doesn't embrace or support, isn't liable for, and
                                repudiates all risk as for Third-Party Services, including, without
                                limit, the protection rehearses, information security measures, or
                                different arrangements identified with Third-Party Services. Client
                                consents to postpone any case against S7 Works regarding any
                                Third-Party Services and will guarantee it has bought or gotten all
                                fundamental licenses needed to appropriately access and utilize
                                Third-Party Services.{' '}
                            </p>
                            <p>
                                2.4 Customer will utilize sensible measures to forestall unapproved
                                admittance to or utilization of the Services and inform S7 Works
                                speedily upon Customer becoming mindful of any such unapproved
                                access or use. Other than S7 Works's express commitments under
                                Section 7 (Data Protection and Security), S7 Works accepts no
                                accountability or responsibility for Customer Data and the results
                                of gathering, submitting and utilizing it inside the Services.{' '}
                            </p>
                            <p>
                                2.5 Customer will be completely answerable for all utilization under
                                Customer's record and for Users' consistence with this Agreement.
                                Any break of this Agreement by a User will be considered to be a
                                break by Customer. S7 Works's relationship is with Customer and not
                                individual Users or outsiders utilizing the Services through
                                Customer. Client will address all cases raised by its Users and
                                outsiders utilizing the Services through Customer straightforwardly
                                with S7 Works. Client should guarantee that all outsiders that use
                                the Services through Customer concur (I) to utilize the Services in
                                full consistence with this Agreement; and (ii) to the degree allowed
                                by pertinent law, to forgo any cases straightforwardly against S7
                                Works identified with the Services.
                            </p>
                        </div>
                        <div
                            className="termscontent"
                            style={{ textAlign: 'left', marginLeft: '32px', marginRight: '32px' }}
                        >
                            <div className="label"> 3. Limitations </div>
                            <p>
                                Client will not, and won't allow any Users or any outsider to:
                                figure out, decompile, dismantle, interpret, or in any case endeavor
                                to find the source code, object code, or basic construction,
                                thoughts, or calculations of the Services, Software, equipment
                                tokens, or any information identified with the Services (but to the
                                degree such denial is in opposition to material law that can't be
                                prohibited by the understanding of the Parties); change, decipher,
                                or make subordinate works dependent on the Services or Software;
                                share, lease, rent, advance, exchange, sublicense, convey, use, or
                                in any case move the Services or Software for time-sharing or
                                administration department purposes or for any reason other than its
                                own utilization, besides as explicitly gave in a relevant Order
                                Form; fabricate a serious item or administration; endeavor to
                                acquire unapproved admittance to confined parts of the Service;
                                openly disperse Services execution data or examination, including
                                without impediment benchmarks, besides with S7 Works's earlier
                                composed assent; or utilize the Services or Software other than as
                                per this Agreement and in consistence with appropriate law.{' '}
                            </p>
                        </div>
                        <div
                            className="termscontent"
                            style={{ textAlign: 'left', marginLeft: '32px', marginRight: '32px' }}
                        >
                            <div className="label"> 4. Installment of Fees </div>
                            <p>
                                4.1 Customer will pay S7 Works all expenses indicated all together
                                Forms ("Fees") in addition to every single appropriate deal, use,
                                and other buy related charges (or give S7 Works a substantial
                                authentication of exception from the necessity of paying deals, use,
                                or other buy related duties) as per the installment timetable and
                                installment terms set out on the material Order Form. Client will be
                                liable for all assessments identified with the Services and this
                                Agreement, elite of expenses on S7 Works's pay. Besides as in any
                                case showed in the relevant Order Form, all charges and costs will
                                be in U.S. dollars. Neglected and due Fees are dependent upon a
                                money charge of one percent (1.0%) each month, or the most extreme
                                allowed by law, whichever is lower, in addition to all costs of
                                assortment, including sensible lawyers' charges, but to the degree
                                appropriate law requires an alternate revenue or money charge
                                estimation for neglected and due Fees and costs. On account of any
                                retention necessities, Customer will pay any necessary retention
                                itself and won't diminish the sum paid to S7 Works on account
                                thereof. On the off chance that the technique for installment is
                                with Visa, Customer consents to (I) keep Customer's Visa data
                                refreshed and (ii) approve charging Customer's Mastercard the Fees
                                when due. S7 Works won't charge Users any expenses for their
                                utilization of the Services or Software without Customer's approval
                                and the Software can be downloaded by Users for nothing. Clients'
                                transporters or specialist co-ops might charge expenses for
                                information use, informing, calls, or different administrations that
                                are needed for them to utilize the Services.{' '}
                            </p>
                            <p>
                                4.2 If Customer utilizes just S7 Works Services accessible for
                                nothing on its website page ("Free Services"), S7 Works won't charge
                                Customer any Fees for utilization of such Free Services or download,
                                establishment, or utilization of the Software related with the Free
                                Services. Such Customer might cease utilizing the Free Services
                                whenever, yet should quickly eliminate any Software from its
                                gadgets.{' '}
                            </p>
                            <p>
                                4.3 At any time during the Term, and except if in any case consented
                                to recorded as a hard copy by the Parties, any expansion or overage
                                in the most extreme number of Users determined in the Order Form
                                will be treated as per this Section 4.3 ("Subscription Upgrade").
                                The greatest number of Users will be expanded as follows:{' '}
                            </p>
                            <p>
                                For Subscription Upgrades (I) for Customers where the most extreme
                                number of Users on the Order Form is less than 500 Users, the most
                                extreme number of Users will be expanded consequently in increases
                                equivalent to 50 Users, (ii) for Customers where the most extreme
                                number of Users on the Order Form is 500 - 1000 Users, the greatest
                                number of Users will be expanded naturally in increases equivalent
                                to 100 Users, and (iii) for Customers where the most extreme number
                                of Users on the Order Form is 1001 or more noteworthy, the greatest
                                number of Users will be expanded naturally in increases equivalent
                                to 250 Users.{' '}
                            </p>
                        </div>
                        <div
                            className="termscontent"
                            style={{ textAlign: 'left', marginLeft: '32px', marginRight: '32px' }}
                        >
                            <div className="label"> 5. Secrecy </div>
                            <p>
                                5.1 The expression "Classified Information" signifies any data
                                unveiled by one party ("Disclosing Party") to the next party
                                ("Receiving Party") in any structure (composed, oral, and so on)
                                that is set apart as private or that ought to be sensibly perceived
                                to be classified given the idea of the data and the conditions of
                                the exposure, including, without restriction: proprietary
                                advantages; innovation and specialized data (licensed innovation,
                                creations, ability, thoughts, and techniques); business, monetary,
                                and client data (counting Customer Data and Customer individual
                                information); estimating, conjectures, systems, and item improvement
                                plans; or potentially the details of this Agreement.
                                Notwithstanding, Confidential Information will exclude any data that
                                (I) is or opens up to general society with no activity by, or
                                inclusion of, the Receiving Party; or (ii) was in its ownership or
                                known by it before receipt from the Disclosing Party; or (iii) was
                                legitimately unveiled to it without limitation by an outsider; or
                                (iv) was freely evolved without utilization of any Confidential
                                Information of the Disclosing Party.{' '}
                            </p>
                            <p>
                                5.2 Each party comprehends that the Disclosing Party has or may
                                reveal Confidential Information regarding this Agreement, yet that
                                the Receiving Party will get no rights in, or licenses to, such
                                Confidential Information. The Receiving Party will not unveil or
                                utilize any Confidential Information of the Disclosing Party for any
                                reason outside of the extent of this Agreement, besides with the
                                Disclosing Party's earlier composed authorization. The Receiving
                                Party concurs: (I) to not unveil Confidential Information to any
                                outsider other than those of its workers, project workers,
                                consultants, financial backers, and likely acquirers
                                ("Representatives") with a need to approach thereto and who have
                                gone into non-exposure and non-use arrangements relevant to the
                                Disclosing Party's Confidential Information, and (ii) to utilize
                                such Confidential Information exclusively as sensibly needed
                                regarding the Services or potentially this Agreement. Each Party
                                consents to be answerable for any break of this Agreement brought
                                about by any of its Representatives. The Receiving Party further
                                consents to take similar security insurances to ensure against
                                unapproved exposure or unapproved utilization of such Confidential
                                Information of the Disclosing Party that the Party takes with its
                                own private or restrictive data, yet in no occasion, will a Party
                                apply not exactly sensible safety measures to ensure such
                                Confidential Information. Each Party recognizes that the utilization
                                of such safeguards isn't an assurance against unapproved exposure or
                                use.{' '}
                            </p>
                            <p>
                                5.3 Nothing in this Agreement will forestall the Receiving Party
                                from revealing Confidential Information as needed because of a
                                solicitation under appropriate open records laws or compliant with
                                any legal or administrative request, given that, to the degree
                                allowed by law, the Receiving Party gives the Disclosing Party
                                sensible earlier notification to challenge such an exposure. For the
                                aversion of uncertainty, Customer recognizes that S7 Works uses the
                                administrations of, and Customer might demand extra administrations
                                from, certain outsiders regarding S7 Works's arrangement of the
                                Services, (for example, information facilitating, telephone utility
                                suppliers, and Customer's Third-Party Services suppliers) and such
                                outsiders will approach Customer's Confidential Information,
                                remembering Customer Data and Customer Personal Data for
                                understanding with this Agreement.{' '}
                            </p>
                            <p>
                                5.4 Each Party concurs that, upon the composed solicitation of the
                                Disclosing Party, the Receiving Party will instantly get back to the
                                Disclosing Party, or give composed affirmation of the obliteration
                                of, all Confidential Information of the Disclosing Party, including
                                all Confidential Information contained in inner reports, without
                                holding any duplicate, concentrate, or synopsis of any part thereof.
                                In any case the prior, a Receiving Party might hold duplicates of
                                Confidential Information exclusively to the degree vital for
                                motivations behind such party's standard course of business inside
                                archive maintenance and reinforcement prerequisites and systems,
                                given that such Confidential Information will stay subject to the
                                agreements of this Agreement for such a long time as it is held.{' '}
                            </p>
                            <p>
                                5.5 Customer recognizes that S7 Works doesn't wish to get any
                                Confidential Information from Customer that isn't required for S7
                                Works to play out its commitments under this Agreement, and, except
                                if the Parties explicitly concur something else, S7 Works may
                                sensibly assume that any disconnected data got from Customer isn't
                                Confidential Information, except if such data is set apart as
                                "Classified."{' '}
                            </p>
                        </div>
                        <div
                            className="termscontent"
                            style={{ textAlign: 'left', marginLeft: '32px', marginRight: '32px' }}
                        >
                            <div className="label"> 6. Protected innovation Rights; Ownership </div>
                            <p>
                                Besides as explicitly put forward in this, S7 Works alone (and its
                                licensors, where relevant) will hold all protected innovation rights
                                identifying with the Services or the Software or any ideas,
                                thoughts, upgrade demands, input, proposals or other data given by
                                Customer or any outsider identifying with the Services or
                                potentially the Software, which are thus alloted to S7 Works. Client
                                won't duplicate, disseminate, replicate, or utilize any of the prior
                                besides as explicitly allowed under this Agreement. As between the
                                Parties, S7 Works claims every Aggregated Datum. This Agreement
                                isn't a deal and doesn't pass on to Customer any privileges of
                                proprietorship in or identified with the Services or Software, or
                                any licensed innovation rights.{' '}
                            </p>
                        </div>
                        <div
                            className="termscontent"
                            style={{ textAlign: 'left', marginLeft: '32px', marginRight: '32px' }}
                        >
                            <div className="label">7. Information Protection and Security </div>
                            <p>
                                7.1 regarding the Services, S7 Works might deal with Customer Data
                                that is secured "individual information", "individual data" or "by
                                and by recognizable data" under pertinent information insurance law
                                ("Customer Personal Data"). On the off chance that and to the degree
                                S7 Works measures Customer Personal Data ensured by information
                                insurance laws relevant to the European Economic Area, Switzerland
                                or potentially the UK (counting the General Data Protection
                                Regulation (GDPR) or any replacement enactment), then, at that point
                                the particulars of the S7 Works Data Processing Addendum (DPA) will
                                be consolidated into and structure a vital piece of this Agreement.
                                The DPA sets out the gatherings commitments regarding information
                                assurance and security when preparing such Customer Data for your
                                benefit regarding the Services.{' '}
                            </p>
                            <p>
                                7.2 Customer (counting its Users) may empower combinations between
                                the Services and sure of its Third-Party Services (each, an
                                "Incorporation"). By empowering an Integration between the Services
                                and its Third-Party Services, Customer is explicitly educating S7
                                Works to share the Customer Data important to work with the
                                Integration. Client recognizes it is exclusively answerable for
                                giving all directions to the Third-Party Service supplier about the
                                utilization and insurance of Customer Data, including any Customer
                                Personal Data, and for going into any necessary concurrences with
                                the Third-Party Service supplier to ensure such Customer Data or
                                manage its utilization by the Third-Party Service supplier. Client
                                recognizes S7 Works and Third-Party Service suppliers are not
                                sub-processors of one another.{' '}
                            </p>
                            <p>
                                7.3 Customer will be liable for deciding if the Services are fitting
                                for the capacity and preparing of Customer Data (counting Customer
                                Personal Data) under material information insurance law. Client
                                addresses and warrants to S7 Works that the Customer Personal Data
                                gave to or in any case gathered by S7 Works regarding the Services,
                                the means by which Customer obtained such Customer Personal Data,
                                and the Customer's guidelines for the preparing of Customer Personal
                                Data regarding the Services will conform to all appropriate
                                information assurance laws.{' '}
                            </p>
                            <p>
                                7.4 S7 Works will execute and keep up with all sensible and suitable
                                specialized and associations safety efforts intended to shield
                                Customer Data from any unintentional or unlawful obliteration,
                                adjustment, or unapproved access or exposure to outsiders as per the
                                S7 Works Security strategy (the most recent variant of which is
                                accessible here: https://www.timedoctor.com/iso-27001.html or such
                                other URL as might be informed to Customer every now and then).{' '}
                            </p>
                        </div>
                        <div
                            className="termscontent"
                            style={{ textAlign: 'left', marginLeft: '32px', marginRight: '32px' }}
                        >
                            <div className="label">8. Indemnification </div>
                            <p>
                                8.1 For Customers took on any type of Services requiring the
                                installment of Fees, S7 Works will repay and hold Customer innocuous
                                from risk to outsiders coming about because of encroachment by the
                                Services of any patent or any copyright or misappropriation of any
                                proprietary advantage, if S7 Works is immediately told of any
                                dangers, cases, and procedures related thereto and offered sensible
                                help and the chance to expect sole authority over safeguard and
                                settlement; S7 Works won't be answerable for any settlement it
                                doesn't support. The prior commitments don't have any significant
                                bearing concerning segments or parts of the Services (I) not made by
                                S7 Works; (ii) bringing about entire or to a limited extent from
                                Customer particulars; (iii) that are adjusted after conveyance by S7
                                Works; (iv) joined with different items, cycles, or materials where
                                the supposed encroachment identifies with such mix; (v) where
                                Customer proceeds purportedly encroaching movement in the wake of
                                being advised thereof or in the wake of being educated regarding
                                alterations that would have kept away from the supposed
                                encroachment; or (vi) where Customer's utilization of Services isn't
                                totally as per this Agreement and all connected Documentation. On
                                the off chance that S7 Works gets data about a genuine or asserted
                                encroachment or misappropriation guarantee that would be dependent
                                upon repayment rights set out in this Section 8, S7 Works will have
                                the alternative, to its detriment, to (I) change the Software to be
                                non-encroaching or (ii) get a permit for Customer to keep utilizing
                                the Software. In the event that S7 Works decides it isn't
                                industrially sensible to perform both of the above choices, then, at
                                that point S7 Works may at its choice choose to end the permit for
                                the Services and discount the unmerited piece of any prepaid
                                membership Fees, customized consistently. THIS SECTION STATES
                                CUSTOMER'S SOLE AND EXCLUSIVE REMEDY FOR INFRINGEMENT,
                                MISAPPROPRIATION, AND/OR CLAIMS ALLEGING INFRINGEMENT OR
                                MISAPPROPRIATION. S7 Works's commitments under this Section 8 don't
                                have any significant bearing to Customer's utilization of Free
                                Services.{' '}
                            </p>
                            <p>
                                8.2 Customer will reimburse S7 Works from all harms, costs,
                                settlements, lawyers' charges, and costs identified with any case
                                identified with Customer's break of Section 2 "Client
                                Responsibilities," Section 3 "Limitations," Section 5
                                "Classification," Section 6 "Licensed innovation Rights and
                                Ownership," or Section 7 "Information Protection." Without
                                constraint, Customer will guard and repay S7 Works against any case
                                made or brought against S7 Works by a User or an outsider emerging
                                from Customer's arrangement or assortment of Customer Data,
                                including Customer Personal Data, disregarding material law or
                                outsider rights.{' '}
                            </p>
                        </div>
                        <div
                            className="termscontent"
                            style={{ textAlign: 'left', marginLeft: '32px', marginRight: '32px' }}
                        >
                            <div className="label">9. Term; Termination </div>
                            <p>
                                9.1 This Agreement begins on the Effective Date. Subject to prior
                                end as explicitly accommodated in this Agreement, the underlying
                                Term of this Agreement will be for the Term indicated in the Order
                                Form, or in case of different Order Forms, until the Term of all
                                Order Forms has lapsed. Each Order Form and this Agreement will
                                naturally recharge after the underlying Term and any restoration
                                Term for a reestablishment Term equivalent to the terminating
                                membership Term, except if either party gives to the next no less
                                than thirty (30) days earlier composed notification that it won't
                                restore. The Fees per User for every restoration Term will be
                                equivalent to the Fees per User for the quickly earlier Term, in
                                addition to a cost increment. Any valuing increment won't surpass
                                seven percent (7%) each year, except if the evaluating was assigned
                                in the appropriate Order Form as limited time or once; gave, be that
                                as it may, the Fees for every restoration Term will not surpass the
                                rundown cost as of the beginning date of such reestablishment Term.{' '}
                            </p>
                            <p>
                                9.2 in case of any material break of this Agreement by one or the
                                other party (other than Customer's installment commitments), the
                                non-penetrating party might end this Agreement before the finish of
                                the Term by allowing thirty (30) days earlier composed notification
                                to the penetrating party; gave, notwithstanding, that this Agreement
                                won't end if the penetrating party has relieved the break preceding
                                the lapse of such thirty-day time span. On the off chance that
                                Customer neglects to pay any Fees or different sums in the pertinent
                                Order Form as per the Payment Schedule, S7 Works might end this
                                Agreement before the finish of the Term by giving five (5) work days
                                earlier composed notification to Customer; gave, nonetheless, that
                                this Agreement won't end if Customer has paid all Fees and different
                                sums in the relevant Order Form preceding the termination of such
                                five (5) work day duration.{' '}
                            </p>
                            <p>
                                9.3 Either party might end this Agreement, without notice, (I) upon
                                the establishment or then again if a request is recorded, notice is
                                given, a goal is passed, or a request is made, for each situation by
                                or against the other party under pertinent law identifying with
                                indebtedness, organization, liquidation, receivership, insolvency,
                                or some other wrapping up procedures; (ii) upon the other party's
                                making a task to serve loan bosses or making an intentional plan
                                with its lenders; (iii) upon the other party's disintegration or
                                stopping, or taking steps to stop to work together or (iv) if any
                                occasion happens or continuing is initiated, regarding the other
                                party that has the same or comparative impact to any of the
                                occasions referenced in Section 9.3(i) through (iii). In any case
                                anything in this Agreement unexpectedly, S7 Works may, without
                                punishment or obligation and with or without notice, alter or stop
                                its arrangement of Free Services whenever and to the degree Customer
                                is just utilizing Free Services, promptly end this Agreement.{' '}
                            </p>
                            <p>
                                9.4 The Sections of this Agreement which by their tendency ought to
                                endure end or lapse of this Agreement, including however not
                                restricted to Sections 2 through 13, will endure end or termination
                                of this Agreement. No discount of Fees will be expected in any sum
                                by virtue of end by S7 Works as per this Section 9. In case of end
                                by Customer compliant with this Section 9, Customer will be
                                entitled, as its sole and restrictive cure, to get a discount of any
                                prepaid membership Fees paid by Customer to S7 Works for Services
                                not delivered as of the end date. At the point when this Agreement
                                lapses or ends, S7 Works will stop offering the Types of assistance
                                to Customer.{' '}
                            </p>
                        </div>
                        <div
                            className="termscontent"
                            style={{ textAlign: 'left', marginLeft: '32px', marginRight: '32px' }}
                        >
                            <div className="label">
                                10. Guarantees and Disclaimer of Additional Warranties{' '}
                            </div>
                            <p>
                                10.1 For Customers took on any type of Services requiring
                                installment of Fees, S7 Works addresses and warrants that it won't
                                purposely incorporate, in the Services delivered to Users and gave
                                to Customer hereunder, any PC code or other PC guidelines, gadgets,
                                or methods, including without constraint those known as infections,
                                incapacitating gadgets, trojans, or delayed bombs, that deliberately
                                upset, debilitate, hurt, contaminate, swindle, harm, or in any case
                                block in any way, the activity of an organization, PC program, or PC
                                framework or any part thereof, including its security or Customer
                                Data. In the event that, whenever, S7 Works neglects to agree with
                                the guarantee in this Section 10.1, Customer may immediately tell S7
                                Works recorded as a hard copy of any such resistance. S7 Works will,
                                inside thirty (30) days of receipt of such composed notice, either
                                right the resistance or give Customer an arrangement for revising
                                the rebelliousness. In the event that the resistance isn't remedied
                                or then again if a sensibly satisfactory adjustment plan isn't set
                                up during such period, Customer might end this Agreement and get a
                                discount of any paid ahead of time however unmerited membership
                                Fees, customized consistently, as its sole and restrictive solution
                                for such rebelliousness. This arrangement doesn't make a difference
                                to Customer's utilization of Free Services.{' '}
                            </p>
                            <p>
                                10.2 EXCEPT AS EXPLICITLY PROVIDED IN THIS SECTION 10, THE SERVICES
                                AND S7 WORKS CONFIDENTIAL INFORMATION AND ANYTHING PROVIDED IN
                                CONNECTION WITH THIS AGREEMENT ARE PROVIDED "With no guarantees,"
                                WITHOUT ANY WARRANTIES OF ANY KIND. S7 WORKS HEREBY DISCLAIMS FOR
                                ITSELF AND ITS SUPPLIERS ALL WARRANTIES, EXPRESS OR IMPLIED,
                                INCLUDING, WITHOUT LIMITATION, ALL IMPLIED WARRANTIES, TERMS OR
                                CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                                SATISFACTORY QUALITY, TITLE, AND NON-INFRINGEMENT.{' '}
                            </p>
                            <p>
                                10.3 Each Party addresses and warrants that (I) it has the lawful
                                ability to go into this Agreement, and (ii) its exhibition of this
                                Agreement won't struggle with any commitments it hosts to third
                                gatherings.
                            </p>
                        </div>
                        <div
                            className="termscontent"
                            style={{ textAlign: 'left', marginLeft: '32px', marginRight: '32px' }}
                        >
                            <div className="label">11. Limit of Liability </div>
                            <p>
                                11.1 NOTHING IN THIS AGREEMENT (OR ANY ORDER FORM) SHALL LIMIT OR
                                EXCLUDE EITHER PARTY'S LIABILITY FOR (I) DEATH OR PERSONAL INJURY
                                CAUSED BY ITS NEGLIGENCE, OR THE NEGLIGENCE OF ITS EMPLOYEES,
                                AGENTS, OR SUBCONTRACTORS; (II) FRAUD OR FRAUDULENT
                                MISREPRESENTATION; (III) ITS INDEMNIFICATION OBLIGATIONS; (IV)
                                BREACH OF SECTION 3 "Limitations," SECTION 4 "Installment OF FEES,"
                                OR SECTION 6 "Licensed innovation RIGHTS; OWNERSHIP;" OR (V) ANY
                                OTHER LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED BY LAW.{' '}
                            </p>
                            <p>
                                11.2 SUBJECT TO SECTION 11.1, IN NO EVENT WILL EITHER PARTY OR ITS
                                SUPPLIERS BE LIABLE TO THE OTHER PARTY (OR ANY PERSON CLAIMING
                                THROUGH SUCH PARTY) FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
                                OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH
                                THE USE OF THE SERVICES OR ANYTHING PROVIDED IN CONNECTION WITH THIS
                                AGREEMENT; THE DELAY OR INABILITY TO USE THE SERVICES OR ANYTHING
                                PROVIDED IN CONNECTION WITH THIS AGREEMENT OR OTHERWISE ARISING FROM
                                THIS AGREEMENT, INCLUDING WITHOUT LIMITATION, (I) LOSS OF REVENUE OR
                                ANTICIPATED PROFITS (WHETHER DIRECT OR INDIRECT) OR (II) LOST
                                BUSINESS; OR (III) LOST SALES, WHETHER BASED IN CONTRACT, TORT
                                (INCLUDING ACTIVE AND PASSIVE NEGLIGENCE AND STRICT LIABILITY),
                                BREACH OF STATUTORY DUTY, OR OTHERWISE, EVEN IF THE OTHER PARTY HAS
                                BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.{' '}
                            </p>
                            <p>
                                11.3 SUBJECT TO SECTION 11.1, THE MAXIMUM LIABILITY OF EITHER PARTY
                                OR ITS SUPPLIERS FOR ANY AND ALL CLAIMS UNDER AN APPLICABLE ORDER
                                FORM, WHETHER BASED IN CONTRACT, TORT (INCLUDING ACTIVE AND PASSIVE
                                NEGLIGENCE AND STRICT LIABILITY), OR OTHERWISE, WILL NOT EXCEED, IN
                                THE AGGREGATE, THE FEES PAID OR TO BE PAID TO S7 WORKS UNDER SUCH
                                ORDER FORM DURING THE TWELVE-MONTH PERIOD ENDING ON THE DATE THAT
                                SUCH CLAIM IS FIRST ASSERTED. THE FOREGOING LIMITATION WILL APPLY
                                NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
                                REMEDY.{' '}
                            </p>
                        </div>
                        <div
                            className="termscontent"
                            style={{ textAlign: 'left', marginLeft: '32px', marginRight: '32px' }}
                        >
                            <div className="label">12. Government Matters </div>
                            <p>
                                12.1 Export. Regardless whatever else in this Agreement, Customer
                                may not utilize, or give to any individual or fare or re-send out or
                                permit the fare or re-fare of the Services or anything related
                                thereto or any immediate item thereof, disregarding any limitations,
                                laws, or guidelines of the U.S. Division of Commerce, the U.S.
                                Division of the Treasury Office of Foreign Assets Control, or some
                                other U.S. or then again unfamiliar office or authority. Each party
                                addresses that it isn't named on any U.S. government denied-party
                                list. Client and Users will not access or utilize the Services in a
                                U.S. banned country.{' '}
                            </p>
                            <p>
                                12.2 Anti-Corruption. Client concurs that it has not gotten or been
                                offered any unlawful or ill-advised pay off, payoff, installment,
                                gift, or thing of significant worth from any S7 Works worker or
                                specialist regarding this Agreement. In the event that Customer
                                learns of any infringement of the above limitation, Customer will
                                speedily inform S7 Works.{' '}
                            </p>
                            <p>
                                12.3 Commercial Software. The Services (counting the Software) are
                                "business things" as that term is characterized at FAR 2.101.
                                Whenever procured by or for the benefit of any Executive Agency
                                other than an office inside the U.S. Branch of Defense (DoD), the
                                Government gets, as per FAR 12.211 (Technical Data) and FAR 12.212
                                (Computer Software), just those rights in specialized information
                                and programming generally gave to people in general as characterized
                                in this Agreement. Whenever gained by or for any Executive Agency
                                inside the DoD, the Government gets, as per DFARS 227.7202-3 (Rights
                                in business PC programming or business PC programming
                                documentation), just those rights in specialized information and
                                programming usually gave in this Agreement. Also, DFARS 252.227-7015
                                (Technical Data—Commercial Items) applies to specialized information
                                obtained by DoD offices. Any Federal Legislative or Judicial Agency
                                will get just those rights in specialized information and
                                programming generally gave to the general population as
                                characterized in this Agreement. This Section 12.3 is in lieu of,
                                and supplants, some other FAR, DFARS, DEAR, or other proviso,
                                arrangement, or supplemental guideline that tends to Government
                                rights in PC programming or specialized information under this
                                Agreement. Promoted terms utilized in this Section are characterized
                                in the material FAR or DFARs.
                            </p>
                        </div>
                        <div
                            className="termscontent"
                            style={{ textAlign: 'left', marginLeft: '32px', marginRight: '32px' }}
                        >
                            <div className="label">13. Various </div>
                            <p>
                                13.1 Severability. On the off chance that any arrangement of this
                                Agreement is observed to be unenforceable or invalid, that
                                arrangement will be restricted or wiped out to the base degree
                                essential so this Agreement will in any case stay in full power and
                                impact and enforceable.{' '}
                            </p>
                            <p>
                                13.2 Assignment. This Agreement isn't assignable, adaptable, or
                                sublicensable by Customer besides with S7 Works's earlier composed
                                assent, which will not be preposterously retained. S7 Works might
                                move and dole out any of its privileges and commitments under this
                                Agreement. This Agreement will be restricting upon and will
                                acclimate to the advantage of the Parties hereto and their
                                individual allowed replacements and allowed relegates.{' '}
                            </p>
                            <p>
                                13.3 No Third-Party Beneficiaries. Nothing in this Agreement will
                                give, or is expected to present, on any outsider any advantage or
                                the option to implement any term of this Agreement. No substances
                                other than S7 Works and Customer might end, revoke, or consent to
                                any alteration, waiver, or settlement concerning this Agreement.{' '}
                            </p>
                            <p>
                                13.4 Entire Agreement; Amendment. The two players concur that this
                                Agreement is the finished and selective assertion of the shared
                                comprehension of the Parties and overrides and drops all past
                                composed and oral arrangements, correspondences, and different
                                understandings identifying with the topic of this Agreement. All
                                waivers, corrections, and adjustments should be recorded as a hard
                                copy endorsed by the party against whom the waiver, change, or
                                alteration is to be upheld. No office, association, joint endeavor,
                                or work is made because of this Agreement and Customer doesn't have
                                any authority of any sort to tie S7 Works in any regard at all.{' '}
                            </p>
                            <p>
                                13.5 Notices. All notification under this Agreement will be recorded
                                as a hard copy and will be considered to have been properly given
                                when gotten, if by and by conveyed; when receipt is electronically
                                affirmed, whenever communicated by email; and upon receipt, whenever
                                sent by confirmed or enlisted mail (return receipt mentioned),
                                postage paid ahead of time. S7 Works might give notice utilizing the
                                data gave in the latest Order Form and Customer might give notice
                                utilizing the contact data gave on https://www.timedoctor.com.{' '}
                            </p>
                            <p>
                                13.6 Force Majeure. Any deferral or disappointment in the exhibition
                                of any obligations or commitments of one or the other party (with
                                the exception of the installment of cash owed) won't be viewed as a
                                break of this Agreement if such postponement or disappointment is
                                because of a work debate, fire, tremor, flood, or some other
                                occasion past the sensible control of a party, given that such party
                                immediately informs the other party thereof and utilizes sensible
                                endeavors to continue execution at the earliest opportunity.{' '}
                            </p>
                            <p>
                                13.7 Governing Law; Arbitration. This Agreement will be represented
                                by the laws of the State of Delaware, U.S.A. regardless of its
                                contention of laws arrangements. Any question emerging from or
                                identifying with the topic of this Agreement will be at long last
                                settled by assertion in Washtenaw County, Michigan, U.S.A. Judgment
                                upon the honor so delivered might be entered in a court having
                                locale, or application might be made to such court for legal
                                acknowledgment of any honor and a request for authorization, by and
                                large. In any case the prior, each party will reserve the privilege
                                to found an activity in a court of legitimate locale for injunctive
                                or other fair help forthcoming an official choice by the referee.{' '}
                            </p>
                            <p>
                                13.8 Venue; Prevailing Party. The government and state courts
                                sitting in Washtenaw County, Michigan, U.S.A. will have appropriate
                                and select ward and scene as for any debates emerging from or
                                identified with the topic of this Agreement. Regardless the
                                previous, each party will reserve the privilege to begin and arraign
                                any activity for injunctive help under the steady gaze of any court
                                of capable ward. In any assertion, activity, or continuing to
                                implement rights under this Agreement, the predominant party will be
                                qualified for recuperate expenses and lawyers' charges.{' '}
                            </p>
                            <p>
                                13.9 Publicity. Client consents to partake in press declarations,
                                contextual analyses, expos, or other showcasing sensibly mentioned
                                by S7 Works. During the Term and for thirty (30) days from that
                                point, Customer awards S7 Works the right, for nothing, to utilize
                                Customer's name and additionally logo around the world, to
                                distinguish Customer as such on S7 Works's site or other showcasing
                                or publicizing materials. For clearness, this Section 13.9 does
                                exclude the utilization by S7 Works of Customer Personal Data for
                                showcasing or promoting purposes.{' '}
                            </p>
                            <p>
                                13.10 Counterparts. This Agreement might be executed in partners
                                (counting execution by electronic mark, pdf or other electronic
                                transmission), every one of which will be considered a unique and
                                lawfully restricting and all of which together will be viewed as
                                very much the same arrangement.
                            </p>
                        </div>
                    </div>
                </>

                <Suspense fallback={<></>}>
                    <ChooseBetterWay />
                </Suspense>

                <Suspense fallback={<></>}>
                    <Subscribe />
                </Suspense>
            </div>
        </>
    );
};
